import { Grid, Typography, styled } from '@mui/material';
import { useHistory } from 'react-router';
import { GridActionsCellItem, getGridDateOperators } from '@mui/x-data-grid-premium';
import Chips from 'components/mui/tableElements/Chips';
import {
  APPOINTMENT_SESSION_STATUS,
  GENDERS,
  INITIAL_SESSION_STATUS,
  SESSION_METHODS,
  SESSION_PROGRAM,
  SESSION_STATUS,
  SESSION_TYPES,
} from 'constants/session';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { MuiTableComponent } from 'screens/common/CustomUI';
import {
  AppointmentClosedReasonsType,
  AppointmentStatusTypes,
  SessionCompletionMethodTextConversion,
} from 'utilities/Constants';
import {
  calculateAge,
  formatDate,
  navigateToMemberProfile,
  statusColorOutliner,
  timeFormat,
} from 'utilities/Utilities';
import Modal from './AppointmentDetailsModal';
import PreviewReportModal from 'components/AppointmentDetails/PreviewReportModal';
import { doctorSessionTableAPIs } from 'Services/consultationsService';
import { DOCTOR_CONSULTATION, US_STATES } from 'constants/app';
import Details from './Details';
import moment from 'moment';
import { API_PAYLOAD_DATE_FORMAT } from 'components/ekg/constants/EcgInboxConstants';

export const TableRowText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Work Sans',
}));

const DoctorConsultations = () => {
  const history = useHistory();

  //====== component level state =======//
  const [loading, setloading] = useState(false);
  const [filteredRow, setFilteredRow] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const initialState = {
    page: page,
    pageSize: pageSize,
    appointmentStatus: INITIAL_SESSION_STATUS,
    searchString: '',
    sortParam: '',
    sortOrder: '',
    startDateTime: '',
    endDateTime: '',
  };
  const [apiQueryParams, setapiQueryParams] = useState(initialState);

  //======== redux state ==========//
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);

  useEffect(() => {
    setapiQueryParams((prev) => ({
      ...prev,
      page,
      pageSize,
    }));
  }, [page, pageSize]);

  useEffect(() => {
    tableAPICall(apiQueryParams);
  }, [apiQueryParams]);

  const tableAPICall = (queryParams = null) => {
    setloading(true);
    doctorSessionTableAPIs(teamId, clinicianId, queryParams).then((res) => {
      setFilteredRow(transformAppointmentList(res?.appointments || []));
      setTotalRowCount(res?.totalElements || 0);
      setloading(false);
    });
  };

  /**
   *
   * @param {Array} list
   * @returns array of object which includes custom columns along with api responses
   *  --> startDate, name, age, gender, statusMethods
   */
  const transformAppointmentList = (list) => {
    return list?.reduce((acc, cur) => {
      return [
        ...acc,
        {
          ...cur,
          startDate: cur?.actualStartDateTime || cur?.scheduledStartTime,
          name: `${cur.patient.lastName}, ${cur.patient.firstName}`,
          age: calculateAge(cur.patient.dob),
          gender: cur.patient.gender,
          appointmentMethods:
            SessionCompletionMethodTextConversion[cur?.appointmentCompletionMethod] ||
            AppointmentClosedReasonsType[cur?.closedReasonType] ||
            cur.appointmentStatusDisplayName,
        },
      ];
    }, []);
  };

  const column = [
    {
      field: 'startDate',
      headerName: 'Date',
      width: 150,
      type: 'date',
      valueFormatter: ({ value }) => `${formatDate(value)}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{formatDate(params?.row?.startDate)}</TableRowText>
      ),
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 150,
      filterable: false,
      sortable: false,
      type: 'dateTime',
      valueFormatter: ({ value }) => `${timeFormat(value)}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{timeFormat(params?.row?.startdatetime)}</TableRowText>
      ),
    },
    {
      field: 'name',
      headerName: 'Member',
      width: 200,
      editable: false,
      type: 'string',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => <TableRowText variant="body2">{params?.row?.name}</TableRowText>,
    },
    {
      field: 'age',
      headerName: 'Age',
      width: 60,
      type: 'number',
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.age || '--'}</TableRowText>
      ),
    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 120,
      type: 'singleSelect',
      valueOptions: GENDERS,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.gender || '--'}</TableRowText>
      ),
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 100,
      type: 'singleSelect',
      valueOptions: US_STATES.map((item) => item.code),
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.region || '--'}</TableRowText>
      ),
    },
    {
      field: 'program',
      headerName: 'Program',
      width: 150,
      type: 'singleSelect',
      valueFormatter: ({ value }) => `${value}`,
      valueOptions: SESSION_PROGRAM,
      renderCell: (params) => (
        <TableRowText variant="body2">{params?.row?.program || '--'}</TableRowText>
      ),
    },
    {
      field: 'appointmentChannelType',
      headerName: 'Type',
      width: 150,
      type: 'singleSelect',
      valueOptions: SESSION_TYPES,
      valueGetter: (value) => value.row.appointmentChannelType.split(' ')[0],
      renderCell: (params) => (
        <TableRowText variant="body2">
          {params?.row?.appointmentChannelType.split(' ')[0] || '--'}
        </TableRowText>
      ),
    },
    {
      field: 'appointmentStatus',
      headerName: 'Status',
      width: 120,
      type: 'singleSelect',
      valueOptions: SESSION_STATUS,
      valueGetter: (params) => params?.row?.appointmentStatus,
      renderCell: (params) => (
        <Chips
          label={params?.row?.appointmentStatus || '--'}
          color={statusColorOutliner(params?.row?.appointmentStatus)}
        />
      ),
    },
    {
      field: 'appointmentMethods',
      headerName: 'Method/Reason',
      width: 150,
      type: 'singleSelect',
      valueOptions: SESSION_METHODS,
      valueGetter: (params) => params?.row?.appointmentMethods,
      renderCell: (params) => (
        <TableRowText variant="body2">
          {params?.row?.appointmentStatus === APPOINTMENT_SESSION_STATUS.WAITING_ROOM || params?.row?.appointmentStatus === APPOINTMENT_SESSION_STATUS.ENCOUNTER_SUMMARY_PREPARATION || params?.row?.appointmentStatus === APPOINTMENT_SESSION_STATUS.SCHEDULED ? '--' : params?.row?.appointmentMethods}
        </TableRowText>
      ),
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 100,
      sorting: false,
      hideable: false,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.appointmentId}
          label="View Profile"
          onClick={() => viewMemberProfile(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          key={params.row.appointmentId}
          label="View Details"
          onClick={() => viewSessionDetails(params.row)}
          disabled={params.row.appointmentStatusId === AppointmentStatusTypes.Completed}
          showInMenu
        />,
        <GridActionsCellItem
          key={params.row.appointmentId}
          label="View Report"
          onClick={() => viewSessionReport(params.row)}
          disabled={params.row.appointmentStatusId !== AppointmentStatusTypes.Completed}
          showInMenu
        />,
        <GridActionsCellItem
          key={params.row.appointmentId}
          label="Edit Report"
          onClick={() => editSessionReport(params.row)}
          disabled={
            params.row.appointmentStatusId === AppointmentStatusTypes.Scheduled ||
            params.row.appointmentStatusId === AppointmentStatusTypes.Completed
          }
          showInMenu
        />,
      ],
    },
  ];

  const columnsiFilter = useMemo(
    () =>
      column.map((col) => {
        if (col.type !== 'date') {
          return col;
        }

        return {
          ...col,
          filterOperators: getGridDateOperators().filter(
            (operator) => operator.value === 'after' || operator.value === 'before',
          ),
        };
      }),
    [column],
  );
  /**
   * custom functionality for mui data grid table's
   * custom filter
   * custom sorting
   * and custom column visibility
   */
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    time: true,
  });

  const onSortModelChange = useCallback((param) => {
    setapiQueryParams((prev) => ({
      ...prev,
      sortParam: param[0]?.field,
      sortOrder: param[0]?.sort,
    }));
  }, []);

  const onFilterModelChange = useCallback((param) => {
    if (param.items?.length == 0) {
      setapiQueryParams((prev) => ({
        ...initialState,
      }));
    }
    param.items.forEach((element) => {
      if (element?.columnField == 'name' || element?.columnField == 'provider') {
        setapiQueryParams((prev) => ({
          ...prev,
          searchString: element?.value,
        }));
      }
      if (element?.columnField === 'appointmentStatus') {
        setapiQueryParams((prev) => ({
          ...prev,
          appointmentStatus: AppointmentStatusTypes[element?.value],
        }));
      }
      if (element?.columnField === 'startDate' && element?.value) {
        if (element?.operatorValue === 'after')
          setapiQueryParams((prev) => ({
            ...prev,
            startDateTime: moment(element?.value).format(API_PAYLOAD_DATE_FORMAT),
          }));
        else
          setapiQueryParams((prev) => ({
            ...prev,
            endDateTime: moment(element?.value).format(API_PAYLOAD_DATE_FORMAT),
          }));
      }
    });
  }, []);

  // ============= end custom filter/sortng/visibilty =========//

  /**
   * on click of view profile action button
   * will get redirected to member profile screen where user can see member's additional details -->
   *    like programs, health details, activities.. etc.
   */
  const viewMemberProfile = useCallback((row) => {
    navigateToMemberProfile(history, row.patient?.patientId);
  }, []);

  /**
   * on click of  view details action button
   * will open an modal where session related extra content will be displayed -->
   *    like audit trails
   */
  const [viewSessionDetail, setViewSessionDetail] = useState({
    open: false,
    memberId: '',
    appointmentId: '',
    statusId: 0,
  });

  const viewSessionDetails = useCallback((row) => {
    setViewSessionDetail({
      open: true,
      memberId: row.patient?.patientId,
      appointmentId: row?.appointmentId,
      statusId: row?.appointmentStatusId,
    });
  }, []);
  const viewSessionDetailModalCloseHandler = () => {
    setViewSessionDetail({
      ...viewSessionDetail,
      open: false,
    });
  };
  /**
   * on click of  view report action button
   * will open an modal where session documentation will be displayed as pdf -->
   *    like ekg graph
   */
  const [viewReport, setViewReport] = useState({
    open: false,
    memberId: '',
    appointmentId: '',
  });
  const viewSessionReport = useCallback((row) => {
    setViewReport({
      open: true,
      memberId: row.patient?.patientId,
      appointmentId: row.appointmentId,
    });
  }, []);
  const viewReportCloseHandler = () => {
    setViewReport({
      ...viewReport,
      open: false,
    });
  };

  /**
   * on click of  edit details action button
   * will get redirected to This session screen where user can edit details
   * like notes, ecg interpretation, session status/methods
   */
  const editSessionReport = useCallback((row) => {
    navigateToMemberProfile(history, row.patient?.patientId, row.appointmentId, '/true');
  }, []);

  return (
    <>
      <SectionMain
        headerElement={{
          headerText: 'Consultations',
        }}
        bodyElement={[
          {
            childElements: (
              <Grid container>
                <Grid item xs={12}>
                  <MuiTableComponent
                    fileName={DOCTOR_CONSULTATION}
                    getRowId={(row) => row.appointmentId}
                    loading={loading}
                    columns={columnsiFilter}
                    rows={filteredRow}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'startDate', sort: 'desc' }], // initial table should be latest date first
                      },
                    }}
                    columnToolbar={true}
                    filterToolbar={true}
                    densityToolbar={true}
                    exportToolbar={false}
                    pagination
                    paginationMode="server"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    rowsPerPageOptions={[20, 50, 100, totalRowCount]}
                    rowCount={totalRowCount}
                    onFilterModelChange={(data) => onFilterModelChange(data)}
                    onSortModelChange={(param) => onSortModelChange(param)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                  />
                </Grid>
              </Grid>
            ),
          },
        ]}
      />

      {/* this modal will showcase like ecgs and other notes   */}
      {viewSessionDetail.open ? (
        <Modal
          appointmentinfofull={viewSessionDetail.statusId}
          show={viewSessionDetail.open}
          handleClose={() => viewSessionDetailModalCloseHandler()}
          component={'popover'}
          openEditReport={() =>
            editSessionReport({
              patient: { patientId: viewSessionDetail.memberId },
              appointmentId: viewSessionDetail.appointmentId,
            })
          }
        >
          <Details appointmentId={viewSessionDetail.appointmentId} clinicianId={clinicianId} />
        </Modal>
      ) : null}

      {/* This modal shows pdf report of a session */}
      {viewReport.open ? (
        <PreviewReportModal
          open={viewReport.open}
          memberId={clinicianId}
          patientId={viewReport.memberId}
          appointmentId={viewReport.appointmentId}
          handlePreviewReportModalClose={viewReportCloseHandler}
        />
      ) : null}
    </>
  );
};

export default DoctorConsultations;
