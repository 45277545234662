import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, Prompt } from 'react-router';

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import moment from 'moment';
import axios from 'axios';
import Skeleton from '@mui/lab/Skeleton';
// import { PhoneIcon, VideocamIcon } from '@mui/icons-material/Phone';
import classes from './ThisSessionStyles.module.css';
import SkeletonLoader from 'components/Loading/SkeletonLoader';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import TextInputs from 'components/mui/formElements/TextInputs';
import EkgModal from 'components/EkgModal';
import History from './History';

import { GoAPI } from 'Axios/axios';
import * as API from 'Services/API/actions';
import { getTimezoneName } from 'utilities/Utilities';
import TeamAlert from 'components/Shared/Alert/TeamAlert';
import { AppointmentStatusTypes, sessionCompletedMethod } from 'utilities/Constants';
import { getToken } from 'auth/AuthUtilities';
import { EKG_COLOR, EKG_TYPE } from './constant';
import { getEKGAlgorithmDeterminationDisplayText } from 'constants/recordingMaps';
import { EKG_EMOJI } from 'assets/assetPath/constant';
import { AUTH_TYPE, COACH_ROUTES, DOCTOR_ROUTES } from 'constants/app';

const SessionContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Work sans',
  width: '100%',
  '& .MuiFormControl-root': {
    margin: '10px 0',
  },
  '& .MuiTypography-body1': {
    fontWeight: '500 !important',
    fontFamily: 'Work Sans !important',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px !important',
  },
  '& .MuiTypography-body2': {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '10px',
    fontWeight: '400 !important',
    fontFamily: 'Work Sans !important',
    fontSize: '14px !important',
  },
  '& .MuiSelect-root': {
    fontFamily: 'Work Sans',
  },
  '& .MuiSelect-select': {
    '& :focus': {
      backgroundColor: 'white',
    },
  },
  '& .Mui-selected': {
    backgroundColor: '#F4FAF8 !important',
    // '&:hover': {
    //   backgroundColor: 'green !important',
    // },
  },
  '& .MuiMenuItem-root': {
    fontFamily: 'Work Sans !important',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #484850',
  },

  // theme.spacing(2)
}));

function ThisSession({
  setIsCompleted,
  setPreviewReportModalOpen,
  isCompleted,
  setReportSent,
  reportSent,
}) {
  const { memberId, appointmentId, postSession } = useParams();
  const history = useHistory();

  //==== ====== local state ============//
  const [data, setData] = useState({});
  const [ekgData, setEKGData] = useState(null);
  const [loading, setloading] = useState(false);
  const [patientData, setpatientData] = useState(null);
  const [isAdminDisabled, setIsAdminDisabled] = useState(false);
  const [state, setState] = useState({
    status: 0,
    method: 0,
    ekgInterpretationId: '',
    ekgInterpretation: '',
    ekgInterpretationText: '',
    providerNotes: '',
    consultationSummary: '',
    recommendedAction: '',
    followup: '',
  });
  const [showAlert, setShowAlert] = useState({
    data: '',
    error: false,
  });

  // ========= local state end =========//

  //==== ====== redux state ============//
  const { isCallOver } = useSelector((state) => state.adhocCall);
  const userType = useSelector((state) => state.user.chatProfile.userType);
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const permissions = useSelector((state) => state.user.profile.permissions);
  // const patientDetails = useSelector((state) => state.appointments.patientDetails);
  //==== ====== redux state end ============//

  useEffect(() => {
    loadAction();
  }, [isCallOver]);

  const loadAction = useCallback(async () => {
    let offsetInMinutes = new Date().getTimezoneOffset();
    axios
      .get(
        `${
          process.env.REACT_APP_TELEKARDIA_API_BASE
        }api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/patient-detail?timeZoneOffset=${offsetInMinutes}&timeZone=${getTimezoneName()}`,
        {
          headers: { Authorization: ` Bearer ${getToken()}` },
        },
      )
      .then((res) => {
        setpatientData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (patientData != null) {
      setIsAdminDisabled(
        permissions?.isAdmin &&
          (patientData.patientAppointment?.appointmentStatusId ==
            AppointmentStatusTypes.Scheduled ||
            patientData.patientAppointment?.appointmentStatusId ==
              AppointmentStatusTypes.MemberInWaitingRoom ||
            patientData.patientAppointment?.appointmentStatusId ==
              AppointmentStatusTypes.InProgress ||
            patientData.patientAppointment?.appointmentStatusId ==
              AppointmentStatusTypes.Encounter_Summary_Preparation),
      );
      setState((prev) => ({
        ...prev,
        status: patientData.patientAppointment?.appointmentStatusId,
        method:
          patientData.patientAppointment?.appointmentStatusId == AppointmentStatusTypes.Completed
            ? sessionCompletedMethod[patientData.patientAppointment?.appointmentCompletionMethod]
            : patientData.patientAppointment?.appointmentStatusId ==
                AppointmentStatusTypes.closed && patientData.patientAppointment?.closedReasonType,
      }));
      setData(patientData);

      //trigger loadEkg api call only user is a doctor type and has ekg attached to the session.
      if (
        patientData?.ekgResults &&
        patientData?.ekgResults?.length > 0 &&
        patientData?.ekgResults[0]?.ekgRecordId !== ''
      )
        loadEkgAction(patientData?.ekgResults[0]?.ekgRecordId);
      if (
        patientData?.patientAppointment?.appointmentStatusId ==
        AppointmentStatusTypes.Encounter_Summary_Preparation
      )
        setReportSent(false);
    }
    API.getAppointmentNotes(teamId, clinicianId, appointmentId).subscribe(
      (res) => {
        if (res.data.appointmentNote) {
          setState((prev) => ({
            ...prev,
            providerNotes: res.data.appointmentNote,
          }));
        }
      },
      // eslint-disable-next-line no-console
      (error) => console.log(error),
    );
  }, [patientData]);

  const loadEkgAction = useCallback((recordingid) => {
    setloading(true);
    let samps = GoAPI.get(`/i/v1/recordings/${recordingid}/samples`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    let recs = GoAPI.get(`/i/v1/recordings/${recordingid}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    axios
      .all([recs, samps])
      .then(
        axios.spread((...responses) => {
          setEKGData({ ...responses[0].data, ...responses[1].data });
          setloading(false);
        }),
      )
      .catch((error) => {
        console.log(error);
        setEKGData(null);
        setloading(false);
      });
  }, []);

  // all get call for form fields
  useEffect(() => {
    let getHeartConditions, getHeartId, getDuration, summaryNotes, recommendedAction;
    if (appointmentId != ' ') {
      getHeartConditions = API.heartConditions().subscribe(
        (res) => {
          if (res.data.result != null) {
            let conditions = [
              {
                id: 0,
                heartCondition: 'Select EKG Interpretation',
                heartConditionAdditionalText: '',
              },
              ...res.data.result,
            ];
            setState((prev) => ({
              ...prev,
              ekgInterpretation: conditions,
            }));
          }
          // let heartConditions = res.data.result.sort(function (a, b) {
          //   return a.id - b.id;
          // });
        },
        (error) => {
          console.log(error);
        },
      );
      getDuration = API.getdurationText(teamId, clinicianId, appointmentId).subscribe(
        (res) => {
          res.data.result !== null
            ? setState((prev) => ({
                ...prev,
                followup: res.data.result,
              }))
            : setState((prev) => ({
                ...prev,
                followup: 'No Follow-up',
              }));
        },
        (error) => {
          console.log(error);
        },
      );
      summaryNotes = API.getconsultationSummaryNotes(teamId, clinicianId, appointmentId).subscribe(
        (res) => {
          res.data.result !== null &&
            setState((prev) => ({
              ...prev,
              consultationSummary: res.data.result,
            }));
        },
        (error) => console.log(error),
      );
      recommendedAction = API.gettreatmentPlan(teamId, clinicianId, appointmentId).subscribe(
        (res) => {
          res.data.result !== null &&
            setState((prev) => ({
              ...prev,
              recommendedAction: res.data.result,
            }));
        },
        (error) => console.log(error),
      );
      getHeartId = API.getheartConditionId(teamId, clinicianId, appointmentId).subscribe(
        (res) => {
          res.data.result !== null
            ? setState((prev) => ({
                ...prev,
                ekgInterpretationId: res.data.result,
              }))
            : setState((prev) => ({
                ...prev,
                ekgInterpretationId: 0,
              }));
        },
        (error) => console.log(error),
      );
    }
    return () => {
      if (getHeartConditions) getHeartConditions.unsubscribe();
      else if (getHeartId) getHeartId.unsubscribe();
      else if (getDuration) getDuration.unsubscribe();
      else if (summaryNotes) summaryNotes.unsubscribe();
      else if (recommendedAction) recommendedAction.unsubscribe();
    };
  }, [appointmentId]);

  //ekg interpretation get value rendering logic
  useEffect(() => {
    if (state.ekgInterpretation != '' && state.ekgInterpretationId != '') {
      if (state.ekgInterpretationId == 23) {
        getOverReadReportMethod();
      } else {
        setState((prev) => ({
          ...prev,
          ekgInterpretationText: state.ekgInterpretation.filter(
            (item) => state.ekgInterpretationId === item.id,
          )[0]['heartConditionAdditionalText'],
        }));
      }
    }
  }, [state.ekgInterpretationId, state.ekgInterpretation]);

  useEffect(() => {
    if (userType === AUTH_TYPE.DOCTOR) {
      if (
        state.consultationSummary.length == 0 ||
        state.method == 0 ||
        state.method == 5 ||
        (ekgData != null && state.ekgInterpretationText === null)
      ) {
        setIsCompleted(false);
      } else setIsCompleted(true);
    } else {
      if (state.providerNotes.length == 0 || state.method == 5 || state.method == 0) {
        setIsCompleted(false);
      } else setIsCompleted(true);
    }
  }, [state]);

  const getOverReadReportMethod = () => {
    API.getOverReadReport(teamId, clinicianId, appointmentId).subscribe(
      (res) => {
        if (res.data.result !== null) {
          setState((prev) => ({
            ...prev,
            ekgInterpretationText: res.data.result,
          }));
        }
      },
      (error) => console.log(error),
    );
  };

  //form field value change logic
  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: event.target.value,
    }));

    if (name === 'status') {
      setState((prev) => ({
        ...prev,
        method: 0,
      }));
      setIsCompleted(false);
    } else if (name === 'method' && value != 0) {
      setIsCompleted(true);
    } else if (name === 'ekgInterpretationId') {
      //trigger PUT api with both EKG interpretationId and its text
      saveEkgHeartId(
        value,
        state.ekgInterpretation.filter((item) => value === item.id)[0][
          'heartConditionAdditionalText'
        ],
      );
      setState((prev) => ({
        ...prev,
        ekgInterpretationText: state.ekgInterpretation.filter((item) => value === item.id)[0][
          'heartConditionAdditionalText'
        ],
      }));
    } else if (name === 'providerNotes') saveMyNotesAsync(value);
    else if (name === 'recommendedAction') saveRecommendedAction(value);
    else if (name === 'followup') saveFollowup(value);
    else if (name === 'consultationSummary') saveConsultationSummary(value);
    else if (name === 'ekgInterpretationText') saveEKGInterpretationText(value);
  };

  const ekgTextTimer = useRef(null);
  const saveEKGInterpretationText = (text) => {
    clearTimeout(ekgTextTimer.current);
    ekgTextTimer.current = setTimeout(() => {
      API.setOverReadReport(teamId, clinicianId, appointmentId, text).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error),
      );
    }, 1500);
  };
  const timer1 = useRef(null);
  //save consultation summary
  const saveConsultationSummary = (note) => {
    clearTimeout(timer1.current);
    timer1.current = setTimeout(() => {
      API.setconsultationSummaryNotes(teamId, clinicianId, appointmentId, note).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error),
      );
    }, 3000);
  };
  //set heartId
  const saveEkgHeartId = (ekgInterpretationId, ekgInterpretationText) => {
    API.setheartConditionId(teamId, clinicianId, appointmentId, ekgInterpretationId).subscribe(
      // API that triggers ekgInterpretationText, should be after success of ekgInterpretationId API.
      (res) => res.data.success && saveEKGInterpretationText(ekgInterpretationText),
      (error) => console.log(error),
    );
  };
  //set recommendedAction
  const timer2 = useRef(null);
  const saveRecommendedAction = (note) => {
    clearTimeout(timer2.current);
    timer2.current = setTimeout(() => {
      API.settreatmentPlan(teamId, clinicianId, appointmentId, note).subscribe(
        (res) => {
          // do nothing
        },
        (error) => console.log(error),
      );
    }, 3000);
  };
  //setFollowup
  const saveFollowup = (id) => {
    API.setdurationText(teamId, clinicianId, appointmentId, id).subscribe(
      (res) => {
        // do nothing
      },
      (error) => console.log(error),
    );
  };
  //post calls of all form fields
  const timer3 = useRef(null);
  const saveMyNotesAsync = (note) => {
    clearTimeout(timer3.current);
    timer3.current = setTimeout(() => {
      API.setAppointmentNotes(
        teamId,
        clinicianId,
        appointmentId,
        note,
        // note),
      ).subscribe(
        (res) => {},
        (error) => {
          console.log('error', error);
        },
      );
    }, 3000);
  };

  //==== session report preview and session completion logic change ====//
  const previewReport = () => {
    setPreviewReportModalOpen(true);
  };

  const completeReport = () => {
    let body = {
      appointmentStatus: state.status,
      appointmentCompletionMethod: state.method,
      consultationSummaryNotes: state.consultationSummary,
      followup: state.followup,
      heartConditionId: ekgData !== null ? state.ekgInterpretationId : 23,
      overReadReportNotes: ekgData !== null ? state.ekgInterpretationText : '',
      patientId: memberId,
      treatmentPlan: state.recommendedAction,
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
    };
    state.status == 10 && delete body.appointmentCompletionMethod;
    API.postConsultation(teamId, clinicianId, appointmentId, body).subscribe(
      (res) => {
        if (Object.keys(res.data).includes('detailed_error')) {
          setReportSent(false); //to set promt if session report is sent.
          setShowAlert({
            data: res.data.detailed_error,
            error: true,
          });
          setTimeout(() => {
            setShowAlert({ data: '', type: null });
          }, 5000);
        } else {
          setReportSent(true); //to set promt if session report is sent.
          setShowAlert({
            data: 'You’re all set” - “Session notes successfully completed',
            error: false,
          });
          setTimeout(() => {
            setShowAlert({ data: '', type: null });
            // history.goBack();
            if (userType === AUTH_TYPE.COACH)
              history.push(`${COACH_ROUTES.MEMBER_PROFILE_BASE}/${memberId}`);
            else history.push(`${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${memberId}`);
          }, 5000);
        }
      },
      (error) => {
        console.error('Unable to process this request due to internal server error.', error);
        setShowAlert({
          data: 'Unable to process this request due to internal server error',
          error: true,
        });
        setTimeout(() => {
          setShowAlert({ data: '', type: null });
        }, 5000);
      },
    );
  };

  const triggerChangeStatusAPI = () => {
    if (state.status == AppointmentStatusTypes.closed && state.method != 0) {
      let body = {
        appointmentReasonType: state.method,
        appointmentStatus: state.status,
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone: getTimezoneName(),
      };

      API.updateAppointmentStatus(teamId, clinicianId, appointmentId, body).subscribe(
        (res) => {
          if (Object.keys(res.data).includes('detailed_error')) {
            setShowAlert({
              data: res.data.detailed_error,
              error: true,
            });
            setTimeout(() => {
              setShowAlert({ data: '', type: null });
            }, 5000);
          } else {
            completeReport();
            setShowAlert({
              data: 'Session status changed successfully',
              error: false,
            });
            setTimeout(() => {
              setShowAlert({ data: '', type: null });
            }, 5000);
          }
        },
        (error) => {
          setShowAlert({
            data: error,
            error: true,
          });
        },
      );
    } else completeReport();
  };
  //======= end =======//

  return (
    <SessionContainer>
      {Object.keys(data).length > 0 &&
        data?.patientAppointment?.appointmentStatusId ===
          AppointmentStatusTypes.Encounter_Summary_Preparation &&
        !reportSent &&
        postSession &&
        !permissions.isAdmin && (
          <Prompt
            when={true}
            message={() =>
              'Session Not Complete - Click “Complete Session” to finalize documentation'
            }
          />
        )}
      {userType === 'Coach' && (
        <SectionMain
          headerElement={{
            headerText: `Session Details`,
          }}
          bodyElement={[
            {
              childElements: (
                <>
                  <Grid item lg={4} md={6} xs={12}>
                    <Typography variant="body2">Date</Typography>
                    <SkeletonLoader isloading={Object.keys(data).length === 0}>
                      <Typography variant="body1">
                        {moment(data?.patientAppointment?.scheduledStartTime).format('ll') || '--'}
                      </Typography>
                    </SkeletonLoader>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <Typography variant="body2">Time</Typography>
                    <SkeletonLoader isloading={Object.keys(data).length === 0}>
                      <Typography variant="body1">{`${moment(
                        data?.patientAppointment?.scheduledStartTime,
                      ).format('LT')} -
                        ${moment(data?.patientAppointment?.scheduledEndTime).format(
                          'LT',
                        )} (${moment(data?.patientAppointment?.scheduledEndTime).diff(
                        moment(data?.patientAppointment?.scheduledStartTime),
                        'minutes',
                      )} mins)`}</Typography>
                    </SkeletonLoader>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12} /*className={classes.callTypeSection}*/>
                    {/* {data &&
                      (data?.patientAppointment?.appointmentChannelTypeId === 'Video Call' ? (
                        <VideocamIcon />
                      ) : (
                        <PhoneIcon />
                      ))} */}

                    {/* <div className={classes.distanceFromIcon}> */}
                    <Typography variant="body2">Method of Session</Typography>
                    <SkeletonLoader isloading={Object.keys(data).length === 0}>
                      <Typography variant="body1">
                        {data?.patientAppointment?.appointmentChannelType}
                      </Typography>
                    </SkeletonLoader>
                    {/* </div> */}
                  </Grid>
                </>
              ),
            },
          ]}
        />
      )}
      {userType === 'Doctor' && (
        <>
          <SectionMain
            headerElement={{
              headerText: `Session Details`,
            }}
            bodyElement={[
              {
                childElements: (
                  <>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Date</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">
                          {moment(data?.patientAppointment?.scheduledStartTime).format('ll') ||
                            '--'}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Time</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{`${moment(
                          data?.patientAppointment?.scheduledStartTime,
                        ).format('LT')} -
                          ${moment(data?.patientAppointment?.scheduledEndTime).format(
                            'LT',
                          )} (${moment(data?.patientAppointment?.scheduledEndTime).diff(
                          moment(data?.patientAppointment?.scheduledStartTime),
                          'minutes',
                        )} mins)`}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                      <Typography variant="body2">Method of Session</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">
                          {data?.patientAppointment?.appointmentChannelType}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body2">Cardiologist</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">
                          {`${data?.patientAppointment?.physician?.lastName}, ${data?.patientAppointment?.physician?.firstName}`}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                  </>
                ),
              },
              {
                childElements: (
                  <>
                    <Grid item lg={4} md={6} xs={12} sm={12}>
                      <Typography variant="body2">Medical History</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.medicalHistory || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sm={12} style={{ padding: '0 12px' }}>
                      <Typography variant="body2">Family History</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">
                          {data?.familyMedicalHistory || '--'}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sm={12}>
                      <Typography variant="body2">Medications</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.medications || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sm={12}>
                      <Typography variant="body2">Social History</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.lifeStyleHistoryStr || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12} sm={12} style={{ padding: '0 12px' }}>
                      <Typography variant="body2">Surgeries</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.surgeryDetails || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                  </>
                ),
              },
            ]}
          />
          <SectionMain
            headerElement={{
              headerText: `Patient Provided Details`,
            }}
            bodyElement={[
              {
                childElements: (
                  <>
                    <Grid item md={6} lg={4}>
                      <Typography variant="body2">Reason for Session</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        <Typography variant="body1">{data?.visitReason || '--'}</Typography>
                      </SkeletonLoader>
                    </Grid>
                    <Grid item md={6} lg={4} style={{ padding: '0 12px' }}>
                      <Typography variant="body2">Symptoms</Typography>
                      <SkeletonLoader isloading={Object.keys(data).length === 0}>
                        {data?.symptoms &&
                          data.symptoms.map((item, i) => {
                            return (
                              <li key={i}>
                                <span
                                  style={{
                                    fontFamily: 'Work Sans',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                  }}
                                >
                                  {item.name}, {item.severity}, {item.duration}
                                </span>
                              </li>
                            );
                          })}
                      </SkeletonLoader>
                    </Grid>
                    <Grid item md={6} lg={4}>
                      <Typography variant="body2">Notes for Cardiologist</Typography>
                      <SkeletonLoader
                        isloading={Object.keys(data).length === 0 && data?.patientAppointment}
                      >
                        <Typography variant="body1">
                          {data?.patientAppointment?.notesToCardiologist || '--'}
                        </Typography>
                      </SkeletonLoader>
                    </Grid>
                  </>
                ),
              },
            ]}
          />
        </>
      )}
      {loading ? (
        <>
          <Skeleton animation="wave" height={20} width="50%" style={{ margin: 20 }} />
          <Skeleton animation="wave" height={20} width="80%" style={{ margin: 20 }} />
        </>
      ) : (
        userType === 'Doctor' &&
        ekgData != null && (
          <SectionMain
            headerElement={{
              headerText: `EKG Review`,
              subheaderText: `Write your instructions or component description here.`,
              helperText: `Required*`,
            }}
            bodyElement={[
              {
                childElements: (
                  <Grid container style={{ margin: '-16px', flexDirection: 'row-reverse' }}>
                    <Grid item xs={4}>
                      <SectionMain
                        headerElement={{
                          childElements: (
                            <Grid container>
                              <Grid item xs={12}>
                                <div
                                  style={{ display: 'flex', flexDirection: 'row', columnGap: 5 }}
                                >
                                  <span
                                    className={classes.bpmspan}
                                    style={{
                                      padding: '2px 6px',
                                      border: `2px solid ${
                                        EKG_COLOR[ekgData?.algorithmDetermination.toUpperCase()]
                                      }`,
                                      borderRadius: 8,
                                      fontWeight: 600,
                                      color:
                                        EKG_COLOR[ekgData?.algorithmDetermination.toUpperCase()],
                                    }}
                                  >
                                    <img
                                      alt={ekgData?.algorithmDetermination}
                                      src={EKG_EMOJI[ekgData?.algorithmDetermination.toUpperCase()]}
                                      style={{
                                        color:
                                          EKG_COLOR[ekgData?.algorithmDetermination.toUpperCase()],
                                      }}
                                    />{' '}
                                    {EKG_TYPE[ekgData?.algorithmDetermination.toUpperCase()]}
                                  </span>
                                  {ekgData?.algorithmPackage !== 'apple' && (
                                    <span
                                      className={classes.bpmspan}
                                      style={{
                                        padding: '2px 6px',
                                        border: '2px solid darkgrey',
                                        fontWeight: 600,
                                        borderRadius: 8,
                                        color: 'dark-gray',
                                      }}
                                    >
                                      {ekgData?.is6l ? 'KM6L' : 'KM1L'}
                                    </span>
                                  )}
                                  <span
                                    className={classes.bpmspan}
                                    style={{
                                      padding: '2px 6px',
                                      border: '2px solid darkgrey',
                                      fontWeight: 600,
                                      borderRadius: 8,
                                      color: 'dark-gray',
                                    }}
                                  >
                                    {ekgData?.algorithmPackage || '-'}
                                  </span>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1">
                                  <span className={classes.bpm}>Heart rate: </span>{' '}
                                  <span className={classes.bpmspan}>
                                    {ekgData?.bpm ? ekgData.bpm : '-'} BPM
                                  </span>
                                </Typography>
                                {/* <Typography variant="body1">
                                  <span className={classes.bpm}>RR Interval: </span>{' '}
                                  <span className={classes.bpmspan}>
                                    {ekgData?.interval ? ekgData.interval : '-'}
                                  </span>
                                </Typography> */}
                                <Typography variant="body1">
                                  <span className={classes.bpm}>AI Determination: </span>{' '}
                                  <span className={classes.bpmspan}>
                                    {getEKGAlgorithmDeterminationDisplayText(
                                      ekgData?.algorithmDetermination,
                                      ekgData.algorithmPackage,
                                      ekgData.bpm,
                                    ) || '-'}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          ),
                        }}
                        bodyElement={[
                          {
                            headerText: `Provider Interpretation`,

                            childElements: (
                              <>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    EKG Interpretation
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    name="ekgInterpretationId"
                                    value={state.ekgInterpretationId}
                                    label="EKG Interpretation"
                                    onChange={handleChange}
                                    disabled={isAdminDisabled}
                                  >
                                    {state.ekgInterpretation.length > 0 &&
                                      state.ekgInterpretation.map((item) => (
                                        <MenuItem value={item.id} key={item.id}>
                                          {item.heartCondition}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                                <br />
                                <TextInputs
                                  label="EKG Interpretation Detail"
                                  placeholder=""
                                  onChange={handleChange}
                                  multiline
                                  rows={8}
                                  disabled={isAdminDisabled}
                                  name="ekgInterpretationText"
                                  value={state.ekgInterpretationText}
                                />
                              </>
                            ),
                          },
                          // {
                          //   headerText: `Tags`,
                          //   childElements: (
                          //     <Grid container>
                          //       <Grid item xs={12}>
                          //         <Typography variant="body2">
                          //           <span className={classes.bpm}>Notes: </span>{' '}
                          //           <span className={classes.bpmspan}>
                          //             {data?.patientNotes || '--'}
                          //           </span>
                          //         </Typography>
                          //       </Grid>
                          //
                          //     </Grid>
                          //   ),
                          // },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <SectionMain
                        headerElement={{
                          childElements: (
                            <Typography variant="body2">
                              <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Date Recorded:</span>{' '}
                              <span style={{ fontWeight: 400, color: 'rgba(0, 0, 0, 0.87)' }}>
                                {moment(ekgData?.recordedAt).format('MMMM Do YYYY, h:mm:ss a')}
                              </span>
                            </Typography>
                          ),
                        }}
                        bodyElement={[
                          {
                            childElements: <EkgModal ecgData={ekgData} />,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                ),
              },
            ]}
          />
        )
      )}
      {userType === AUTH_TYPE.COACH && (
        <SectionMain
          headerElement={{
            headerText: `Session  Notes`,
            subheaderText: `Write you personal notes here. They will not be shared with the member.`,
            helperText: `Required*`,
          }}
          bodyElement={[
            {
              childElements: (
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Provider Notes"
                  name="providerNotes"
                  placeholder="Add your notes for an appointment here."
                  value={state.providerNotes}
                  multiline
                  onChange={handleChange}
                  disabled={isAdminDisabled}
                  rows={4}
                  // defaultValue="Add your notes for an appointment here. "
                />
              ),
            },
          ]}
        />
      )}

      {userType === AUTH_TYPE.DOCTOR && (
        <>
          {/* 
              ASP-269
              History component is responsible for showing ekg, bp and weight recondings for the current month ie 30 days data
          */}
          <History startDateofAppointment={data?.patientAppointment?.scheduledStartTime} />

          <SectionMain
            headerElement={{
              headerText: `Report Documentation`,
              subheaderText: `Write your recommendations to the patient here.`,
              helperText: `Required*`,
            }}
            bodyElement={[
              {
                childElements: (
                  <>
                    <TextField
                      fullWidth
                      label="Consultation Summary"
                      name="consultationSummary"
                      placeholder="Write your consultant summary here. This will be sent to the patient"
                      value={state.consultationSummary}
                      multiline
                      rows={4}
                      disabled={isAdminDisabled}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Recommended Action"
                      name="recommendedAction"
                      placeholder="Write your recommended actions here. This will be sent to the patient"
                      value={state.recommendedAction}
                      multiline
                      onChange={handleChange}
                      disabled={isAdminDisabled}
                      rows={4}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Follow-up</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="followup"
                        value={state.followup}
                        label="Follow-up"
                        disabled={isAdminDisabled}
                        onChange={handleChange}
                      >
                        <MenuItem value={'No Follow-up'}>No Follow-up</MenuItem>
                        <MenuItem value={'3 Days'}>3 Days</MenuItem>
                        <MenuItem value={'1 Week'}>1 Week</MenuItem>
                        <MenuItem value={'2 Weeks'}>2 Weeks</MenuItem>
                        <MenuItem value={'1 Month'}>1 Month</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ),
              },
            ]}
          />
        </>
      )}

      {showAlert.data != '' && <TeamAlert title={showAlert.data} error={showAlert.error} />}

      <SectionMain
        headerElement={{
          headerText: `Session Status`,
          helperText: `Required*`,
        }}
        bodyElement={[
          {
            childElements: (
              <>
                <FormControl>
                  <RadioGroup row name="status" value={state.status} onChange={handleChange}>
                    <FormControlLabel
                      disabled={
                        userType === 'Doctor'
                          ? state.consultationSummary === '' ||
                            (ekgData != null && state.ekgInterpretationText === '') ||
                            patientData?.patientAppointment?.appointmentStatusId == 1 ||
                            patientData?.patientAppointment?.appointmentStatusId == 12 ||
                            patientData?.patientAppointment?.appointmentStatusId == 3
                          : state.providerNotes === '' ||
                            patientData?.patientAppointment?.appointmentStatusId == 1 ||
                            patientData?.patientAppointment?.appointmentStatusId == 12 ||
                            patientData?.patientAppointment?.appointmentStatusId == 3
                      }
                      value={4}
                      control={<Radio />}
                      label="Completed"
                    />
                    <FormControlLabel
                      disabled={
                        userType === 'Doctor'
                          ? state.consultationSummary === '' ||
                            (ekgData != null && state.ekgInterpretationText === '') ||
                            patientData?.patientAppointment?.appointmentStatusId == 1 ||
                            patientData?.patientAppointment?.appointmentStatusId == 12 ||
                            patientData?.patientAppointment?.appointmentStatusId == 3
                          : state.providerNotes === '' ||
                            patientData?.patientAppointment?.appointmentStatusId == 1 ||
                            patientData?.patientAppointment?.appointmentStatusId == 12 ||
                            patientData?.patientAppointment?.appointmentStatusId == 3
                      }
                      value={10}
                      control={<Radio />}
                      label="Incomplete"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                {state.status == AppointmentStatusTypes.Completed && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Method</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="method"
                      value={state.method}
                      label="Method"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Video Call</MenuItem>
                      <MenuItem value={2}>Audio Call</MenuItem>
                      <MenuItem value={3}>Backup Call </MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {state.status == AppointmentStatusTypes.closed && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Method</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="method"
                      value={state.method}
                      label="Method"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Patient No Show</MenuItem>
                      <MenuItem value={2}>Member Technical Issue</MenuItem>
                      <MenuItem value={3}>Provider Technical Issue</MenuItem>
                      <MenuItem value={4}>Member Declined</MenuItem>
                      <MenuItem disabled={true} value={5}>
                        System Closed
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </>
            ),
          },
        ]}
        footerElement={{
          buttonInputProps: (
            <>
              {userType === 'Doctor' && ekgData !== null && (
                <Button
                  variant="outlined"
                  sx={{
                    height: 'fit-content',
                    borderColor: '#6387C5',
                  }}
                  onClick={previewReport}
                  disabled={state.ekgInterpretationText == '' || state.consultationSummary == ''}
                >
                  Preview Report
                </Button>
              )}
              <Button
                variant="contained"
                sx={{
                  height: 'fit-content',
                  backgroundColor: '#6387C5',
                }}
                disabled={
                  !isCompleted ||
                  (permissions?.isAdmin &&
                    patientData?.patientAppointment?.appointmentStatusId == 7)
                }
                onClick={triggerChangeStatusAPI}
              >
                Complete Session
              </Button>
            </>
          ),
        }}
      />
    </SessionContainer>
  );
}

export default ThisSession;
