export const SESSION_STATUS = [
  'Completed',
  'Scheduled',
  'Closed',
  'Canceled',
  'In progress',
  'Encounter-Summary-Preparation',
  // 'Report being generated',
  'Waiting-Room',
  // 'Member is in waiting room',
];
export const SESSION_METHODS = [
  'Phone Call',
  'Video Call',
  'Backup Call',
  'Other',
  'Patient did not turn up',
  "Technical issue at patient's end",
  'Technical issue at my end',
  'Patient declined consultation',
  'System Closed',
  'Member Canceled',
  'Provider Canceled',
];
export const SESSION_TYPES = ['Video', 'Phone'];
export const SESSION_PROGRAM_ECG_INBOX = ['Kardia', 'KardiaComplete', 'KardiaCare', 'KardiaCare+'];
export const SESSION_PROGRAM = ['Kardia', 'KardiaComplete', 'KardiaCare', 'KardiaCare+'];
export const INITIAL_SESSION_STATUS = [1, 2, 3, 4, 5, 7, 10, 12];

export const GENDERS = ['Male', 'Female', 'I Prefer Not To Say'];

export const APPOINTMENT_SESSION_STATUS={
  WAITING_ROOM: 'Waiting-Room',
  ENCOUNTER_SUMMARY_PREPARATION: 'Encounter-Summary-Preparation',
  SCHEDULED: 'Scheduled',
}