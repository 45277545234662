import React from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { Box, Typography, Button } from '@mui/material';
import { Modal } from '@mui/material';
import theme from './Theme'


const ConfirmationModalScreen = (props) => {
  const {
    handlepageconfirmation,
    handleredirection,
    heading,
    Description,
    Descriptioninformation,
    patientIdvalue,
    sucessheading
  } = props;

  return (
    <>
     {sucessheading ?     <ThemeProvider theme={theme}>
    <Modal
      open={true} // Ensure the modal is open
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          width: '368px',
          padding: '16px',
          borderRadius: '16px',
          background: 'var(--Light-Background-Paper, #FFF)',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
          backdropFilter: 'blur(8px)', // Add backdrop filter for blur effect
        }}
      >
        <Typography variant="h5">{sucessheading}</Typography>
      </Box>
    </Modal>
  </ThemeProvider> : 
    <ThemeProvider theme={theme}>
    <Modal
      open={true} // Ensure the modal is open
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          width: '368px',
          padding: '16px',
          borderRadius: '16px',
          background: 'var(--Light-Background-Paper, #FFF)',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
          backdropFilter: 'blur(8px)', // Add backdrop filter for blur effect
        }}
      >
        <Typography variant="h5">{heading}</Typography>
        <Typography variant="customvarient">{Description}</Typography>
        <Box>
          {' '}
          <Typography variant="customvarient">{Descriptioninformation}</Typography>
        </Box>
        <Box
          style={{
            marginTop: '24px',
            gap: '16px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button variant="outlined" onClick={handlepageconfirmation}>
            NO, CANCEL
          </Button>
          <Button  style={{background: "var(--Light-Primary-Main, #6387C5)"}}
          variant="contained"  onClick={handleredirection}>
            YES, LEAVE
          </Button>
        </Box>
      </Box>
    </Modal>
  </ThemeProvider>
  }
   
    </>
   
  );
};

export default ConfirmationModalScreen;
