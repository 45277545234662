// MyComponent.js
import React from 'react';
import { Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  // margin:theme.spacing(2),
  typography: {
    body2lable: {
      color: 'var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))',
      paddingRight: '4px',
    },
    body2key: {
      color: ' var(--Light-Text-Primary, rgba(0, 0, 0, 0.87))',
    },
    subtitle1edit: {
      color: 'var(--Light-Text-Primary, rgba(0, 0, 0, 0.87))',
      fontWeight: 600,
    },
    subtitle2value: {
      borderRadius: '4px',
      background: 'var(--Grey-50, #FAFAFA)',
      padding: '4px 8px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    body2modified: {
      fontSize: '14px',
      padding: '4px 8px',
    },
    heartbeatvalue: {
      color: '#8D8D8D',
      textTransform: 'lowercase'
    },
    body1editied: {
      color: 'var(--text-primary, #182C3D)',
      gap: '4px',
    },
  },
});

function CustomComponent({ text, variant }) {
  return (
    <ThemeProvider theme={theme}>
      <Typography variant={variant}>{text}</Typography>
    </ThemeProvider>
  );
}

export { CustomComponent };
