import SkeletonLoader from 'components/Loading/SkeletonLoader';
import SectionBody from 'layouts/ScreenLayout/sections/SectionBody';
import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import RenderSelectedInputs from 'components/mui/formElements/RenderSelectedInputs';
import { useSelector } from 'react-redux';
import { AV_BLOCKS } from 'components/ekg/constants/EcgInboxConstants';
import RenderSelectedCheckBox from 'components/mui/formElements/RenderSelectedCheckBox';

const Secondaryobervationscreen = ({
  selectedOptions,
  setSelectedOptions,
  errorMessage,
  handleAvBlocksChange,
  avblocks,
  setAvBlocks
}) => {
  const ecgReviewCompletedData = useSelector((state) => state.ekgReview?.reviewDetail);
  let filteredvalues = Object.values(avblocks).map(val=>val?true:false).includes(true)
  
  return (
    <SectionBody sx={{backgroundColor:"#FFF !important" , borderRadius:"12px !important"}}
      headerText="Secondary Observations"
      childElements={
        <Stack direction={'row'} columnGap={1}>
          <SkeletonLoader>
            <Box>
              <Box sx={{ paddingBottom: '16px' }}>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="body1">PVCs</Typography>
                  <Typography style={{ color: 'red' }}>*</Typography>
                </Box>
                <Box>
                  <RenderSelectedInputs
                    values={['None', '1 PVC', '2+ PVCs']}
                    setSelectedOptions={setSelectedOptions}
                    completedValue={ecgReviewCompletedData?.pvcs}
                    name="pvcData"
                  />
                </Box>
                {selectedOptions.pvcData?.length > 0 ? null : (
                  <Typography
                    color="error"
                    variant="body2"
                    style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>

              <Box sx={{ paddingBottom: '16px' }}>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="body2">PACs </Typography>
                  <Typography style={{ color: 'red' }}>*</Typography>
                </Box>
                <Box>
                  <RenderSelectedInputs
                    values={['None', '1 PAC', '2+ PACs']}
                    setSelectedOptions={setSelectedOptions}
                    completedValue={ecgReviewCompletedData?.pacs}
                    name="pacData"
                  />
                </Box>
                {selectedOptions.pacData?.length > 0 ? null : (
                  <Typography
                    color="red"
                    variant="body2"
                    style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>
              <Box sx={{ paddingBottom: '16px' }}>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="body2">QRS</Typography>
                  <Typography style={{ color: 'red' }}>*</Typography>
                </Box>
                <Box>
                  <RenderSelectedInputs
                    values={['Normal', 'Wide QRs']}
                    setSelectedOptions={setSelectedOptions}
                    completedValue={ecgReviewCompletedData?.qrs}
                    name="qrsData"
                  />
                </Box>
                {selectedOptions.qrsData?.length > 0 ? null : (
                  <Typography
                    color="error"
                    variant="body2"
                    style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>

              <Box>
                <Box style={{ display: 'flex' }}>
                  <Typography>Av Blocks</Typography>
                  <Typography style={{ color: 'red' }}>*</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <RenderSelectedCheckBox
                    completedReviewData={ecgReviewCompletedData?.avBlocks}
                    handleChange={handleAvBlocksChange}
                    blocks={AV_BLOCKS}
                  />
                </Box>
              </Box>

              {filteredvalues ? null : (
                <Typography
                  color="error"
                  variant="body2"
                  style={{ color: 'var(--Light-Error-Main, #CC3D3F)' }}
                >
                  {errorMessage}
                </Typography>
              ) }
            </Box>
          </SkeletonLoader>
        </Stack>
      }
    />
  );
};

export default Secondaryobervationscreen;
