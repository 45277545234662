import { AUTH_TYPE, COMPLETTED, DOCTOR_ROUTES } from 'constants/app';
import Timezone from 'moment-timezone';
import moment from 'moment';
import { CANADA_DATE_FORMAT, DATE_FORMAT } from './Constants';

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
export function streamToPromise(stream) {
  return new Promise((resolve, reject) => {
    let res;
    stream.subscribe(
      (result) => {
        res = result;
      },
      (err) => reject(err),
      () => resolve(res),
    );
  });
}

export function getDayStringFromDay(d) {
  let day = '';
  switch (d) {
    case 0:
      day = 'Sunday';
      break;
    case 1:
      day = 'Monday';
      break;
    case 2:
      day = 'Tuesday';
      break;
    case 3:
      day = 'Wednesday';
      break;
    case 4:
      day = 'Thursday';
      break;
    case 5:
      day = 'Friday';
      break;
    case 6:
      day = 'Saturday';
  }
  return day;
}

export function fetchAuthProfileQueryParam(apiString, profile) {
  switch (profile) {
    case profile === AUTH_TYPE.DOCTOR:
      apiString += 'forDoctor=true';
      break;
    case profile === AUTH_TYPE.COACH:
      apiString += 'forCoach=true';
      break;
    default:
      return apiString;
  }
}
export function move(array, old_index, new_index) {
  // Copy incoming array, we want to return a new one
  let arr = [...array];

  // if old index is negative, assume we are looking backward in array (see https://h3manth.com/new/blog/2013/negative-array-index-in-javascript/)
  while (old_index < 0) {
    old_index += arr.length;
  }

  // if new index is negative, assume we are looking backward in array (see https://h3manth.com/new/blog/2013/negative-array-index-in-javascript/)
  while (new_index < 0) {
    new_index += arr.length;
  }

  // if new index is larger than array length, populate missing elements with undefined values
  if (new_index >= arr.length) {
    var k = new_index - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }

  // Insert at new_index the old_index value that we deleted (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice)
  arr.splice(
    new_index /* insert value at new_index */,
    0,
    arr.splice(old_index, 1)[0] /* delete value at old_index and return it */,
  );
  return arr;
}

export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

  return `${getMinutes} : ${getSeconds}`;
};

export function getTimezoneName() {
  return Timezone.tz.guess();
}
export const getLocaleCompareOptions = () => ({
  numeric: false,
  sensitivity: 'variant',
  ignorePunctuation: true,
});
export const SORT_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC',
};
export const StandardCompare = {
  strings: (a = '', b = '', direction = SORT_TYPES.ASC) => {
    let first = a;
    let second = b;

    if (first === null) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }

    if (second === null) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    first = first.trim();
    second = second.trim();

    if (first.length === 0) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }

    if (second.length === 0) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    if (direction === SORT_TYPES.DESC) {
      return second.localeCompare(first, 'en', getLocaleCompareOptions());
    }

    // default ASC
    return first.localeCompare(second, 'en', getLocaleCompareOptions());
  },
  dates: (a = null, b = null, direction = SORT_TYPES.ASC) => {
    if (a === null) {
      return direction === SORT_TYPES.ASC ? 1 : -1;
    }
    if (b === null) {
      return direction === SORT_TYPES.ASC ? -1 : 1;
    }

    const first = new Date(a);
    const second = new Date(b);

    if (direction === SORT_TYPES.DESC) {
      return second - first;
    }

    // default ASC
    return first - second;
  },
  tableStringFilter: (a = null, b = null) => {
    if (a === null || b === null) {
      return false;
    }

    return a.toLowerCase() === b.toLowerCase();
  },
  tableNumberRangeFilter: (
    nums = {
      values: {
        min: null,
        current: null,
        max: null,
      },
      limits: {
        min: Number.NEGATIVE_INFINITY,
        max: Number.POSITIVE_INFINITY,
      },
    },
  ) => {
    if (nums.values.min === nums.limits.min && nums.values.max !== nums.limits.max) {
      return nums.values.current <= nums.values.max;
    } else if (nums.values.min !== nums.limits.min && nums.values.max === nums.limits.max) {
      return nums.values.current >= nums.values.min;
    }

    return nums.values.current >= nums.values.min && nums.values.current <= nums.values.max;
  },
};

export const getPhysicianName = (firstName, lastName, fallbackField) => {
  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
  return getName(firstLetter, lastName, fallbackField);
};

export const getName = (firstName, lastName, fallbackField) => {
  let result = '';

  if (firstName && lastName) {
    result = `${lastName}, ${firstName}`;
  } else if (firstName && !lastName) {
    result = firstName;
  } else if (!firstName && lastName) {
    result = lastName;
  } else {
    result = fallbackField;
  }

  return result;
};

export const ValidatePassword = (password) => {
  // rules
  const minChars = 8;
  const maxChars = 20;
  const containsLowercase = /[a-z]/;
  const containsUppercase = /[A-Z]/;
  const containsWhitespace = /\s/;
  const containsNumber = /\d/;

  const tests = {
    validLength: false,
    noWhitespace: false,
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
  };

  if (password.length <= maxChars && password.length >= minChars) {
    tests.validLength = true;
  }

  tests.noWhitespace = !containsWhitespace.test(password);
  tests.hasLowercase = containsLowercase.test(password);
  tests.hasUppercase = containsUppercase.test(password);
  tests.hasNumber = containsNumber.test(password);

  return tests;
};

export function getFullJid(jid) {
  let { hostname } = new URL(process.env.REACT_APP_CHAT_DOMAIN);
  return `${jid}@${hostname}`;
}
export const makeSimilarReactRouterLocationObject = (location) => ({
  // key: 'ac3df4', // not with HashHistory!
  pathname: location.pathname,
  search: location.search,
  hash: location.hash,
  // state: {
  //   [userDefined]: true
  // }
});

export const toPascalCase = (str) => {
  return str.replace(/\w\S*/g, (m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());
};

export const parseName = (firstName, lastName) => {
  let result = '';

  if (firstName && lastName) {
    result = `${lastName}, ${firstName}`;
  } else if (firstName && !lastName) {
    result = firstName;
  } else if (!firstName && lastName) {
    result = lastName;
  }

  return result;
};

export const getReducedEle = (array) => {
  const data = array?.reduce((accumulator, currentValue) => {
    return `${accumulator !== 0 ? accumulator + ', ' : ''}` + currentValue;
  }, 0);
  return data;
};
export const changeDateTimeFormat = (rawTime) => {
  const utcDate = moment.utc(rawTime);
  const localDate = moment(utcDate).local();
  const month = (1 + localDate['_d'].getMonth()).toString().padStart(2, '0');
  const date = localDate['_d'].getDate().toString().padStart(2, '0');
  const year = localDate['_d'].getFullYear();
  const formattedDate = `${month}/${date}/${year}`;

  const time = localDate['_d'].toLocaleTimeString('en-IT');
  const splitter = time.split(' ');
  const actualTime = splitter[0].split(':');
  const formattedDateTime = formattedDate + ' ' + actualTime[0] + ':' + actualTime[1] + ' ';
  return formattedDateTime;
};

export const getMeridiem = (rawTime) => {
  const utcDate = moment.utc(rawTime);
  const localDate = moment(utcDate).local();
  const mer = localDate.format('a').toUpperCase();
  return mer;
};

export const getDateFromString = (dateString) => {
  const dateArr = dateString.split('-');
  const dateFields = {
    date: dateArr[2],
    month: dateArr[1],
    year: dateArr[0],
  };
  const formattedDate = `${dateFields.month}/${dateFields.date}/${dateFields.year}`;
  return formattedDate;
};

export function getPriorDate(noOfDaysPrior) {
  let priorDate = new Date();
  priorDate.setDate(priorDate.getDate() - noOfDaysPrior);
  return priorDate;
}

export const yearMonthDateFormat = (rawTime) => {
  const rawTimeDate = new Date(rawTime);
  const month = (1 + rawTimeDate.getMonth()).toString().padStart(2, '0');
  const date = rawTimeDate.getDate().toString().padStart(2, '0');
  const year = rawTimeDate.getFullYear();
  const formattedDate = `${year}-${month}-${date}`;
  return formattedDate;
};

export const getYearMonthDateFormat = (rawTime) => {
  const conversion =
    rawTime.toString().includes('Z') || rawTime.toString().includes('+0000')
      ? rawTime.toString().slice(0, 10)
      : rawTime;
  const utcDate = moment.utc(conversion);
  const localDate = moment(utcDate).local();
  const month = (1 + localDate['_d'].getMonth()).toString().padStart(2, '0');
  const date = localDate['_d'].getDate().toString().padStart(2, '0');
  const year = localDate['_d'].getFullYear();
  const formattedDate = `${month}/${date}/${year}`;
  return formattedDate;
};

export const getDateDiff = (d1, d2) => {
  const t2 = d2.getTime();
  const t1 = d1.getTime();
  return parseInt((t2 - t1) / (24 * 3600 * 1000), 10);
};

export const ageCalculate = (dob) => {
  const dobDate = new Date(dob);
  const year = dobDate.getFullYear();
  const currentYear = new Date().getFullYear();
  const age = currentYear - year;
  const formattedAge = age.toString().padStart(2, '0');
  return formattedAge;
};

export const ageCalculateFromString = (dateString) => {
  const dateArr = dateString.split('-');
  const year = dateArr[0];
  const age = new Date().getFullYear() - year;
  const formattedAge = age.toString().padStart(2, '0');
  return formattedAge;
};

export function cmToFeet(height) {
  var realFeet = (height * 0.3937) / 12;
  var feet = Math.floor(realFeet);
  var inches = Math.round((realFeet - feet) * 12);
  return feet + "'" + inches + "''";
}
export const remainingCharacterCount = (textNotes) => {
  return 1000 - textNotes.length + ' characters left';
};
export const nextMultipleOf10 = (number) => {
  var remainder = number % 10;
  if (remainder === 0) {
    return number; // The number is already a multiple of 10
  }
  return number + (10 - remainder);
};

export const reportSubmit = (id) => {
  return `Report ${id} has been successfully submitted.`;
};

export const EkgHeaders = (id) => {
  let columns = [
    {
      id: 'name',
      label: 'Name',
      disableSorting: 1,
    },
    {
      id: 'reportId',
      label: 'Report ID',
      disableSorting: 1,
    },
    {
      id: 'interpretation',
      label: 'Interpretation',
      disableSorting: 1,
    },
    {
      id: 'timeRequesteduested',
      label: 'Time Requested',
      disableSorting: 0,
    },
    {
      id: id === COMPLETTED ? 'timeSubmitted' : 'timeLeft',
      label: id === COMPLETTED ? 'Time Submitted' : 'Time Left',
      disableSorting: 0,
    },
    {
      id: 'status',
      label: 'Status',
      disableSorting: 1,
    },
    {
      id: 'location',
      label: 'Location',
      disableSorting: 1,
    },
  ];
  return columns;
};

export const convertArrayToObject = (array, key, value) => {
  const initialValue = {};
  return array?.reduce((obj, item) => {
    return {
      ...obj,
      [item?.[key]]: item?.[value],
    };
  }, initialValue);
};

// This will give MM//DD//YYYY format if ots US else DD/MM/YYYY format
export const formatDate = (date, country = 'US', dateFormat = DATE_FORMAT) => {
  const format = country?.toUpperCase() === 'US' ? dateFormat : CANADA_DATE_FORMAT;
  return moment(date).format(format);
};
export const timeFormat = (date) => {
  return moment(date).format('hh:mm:ss A');
};
export const calculateAge = (dob) => {
  return moment().diff(dob, 'years');
};

export const flattenObject = (obj, parentKey = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], newKey));
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {});
};

export const statusColorOutliner = (status) => {
  const colorCode = {
    completed: '#067F6F',
    closed: '#FDC246',
    canceled: '#CC3D3F',
    overdue: '#CC3D3F',
    'not started': '#FDC246',
    //rest are 'neutral which is default color in ChipComponent.
  };
  return colorCode[status.toLowerCase()] || 'rgba(0, 0, 0, 0.60)';
};

export const navigateToMemberProfile = (history, memberId, appointmentId = '', editReport = '') => {
  history.push(
    `${DOCTOR_ROUTES.PATIENT_PROFILE_BASE_URI}/${memberId}/${appointmentId}${editReport}`,
  );
};

export const getKeyFromValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const toTitleCase = (str) => {
  const regexStr = str.replace(/_/g, ' ');
  return regexStr.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};
export const dateFormatting = (date) => {
  if (!date) return ''; // Handle null or undefined input
  const timePart = date.split('T')[1].split('.')[0]; // Splitting time part
  let [hours, minutes] = timePart.split(':'); // Split hours and minutes
  hours = parseInt(hours);
  let period; // Variable to store AM/PM designation
  if (hours < 12) {
    period = 'AM';
    if (hours === 0) {
      hours = 12; // Convert 0 to 12 for 12-hour format
    }
  } else {
    period = 'PM';
    if (hours !== 12) {
      hours -= 12; // Convert hours to 12-hour format
    }
  }
  const formattedTime = `${hours}:${minutes} ${period}`;
  return formattedTime;
};

// this will take a string value and convert it to pascalcase as data coming is in "kardia_card" format
export const toPascalCaseString = (str) => {
  return str
    .split('_')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join('');
};
