import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Backdrop, Box, Button, Fade, Modal, Stack, Typography, Grid } from '@mui/material';
import SectionBody from 'layouts/ScreenLayout/sections/SectionBody';
import SkeletonLoader from 'components/Loading/SkeletonLoader';
import { SectionMain } from 'layouts/ScreenLayout/sections/SectionMain';
import PatientChartscreen from 'screens/Ecgscreens/PatientChartscreen';
import BackArrowIcon from 'assets/images/ArrowBackFilled.svg';
import Patientdetailsdata from 'screens/Ecgscreens/Patientdetailsdata';
import Secondaryobervationscreen from 'screens/Ecgscreens/Secondaryobervationscreen';
import { ECG_review_confirmationmodal, ECG_review_page } from 'screens/Ecgscreens/Ecgconstants';
import { useHistory } from 'react-router-dom';
import {
  ageCalculateFromString,
  cmToFeet,
  formatDate,
  getDateFromString,
  timeFormat,
  toPascalCaseString,
} from 'utilities/Utilities';
import { CRP_REGION } from 'constants/ekgReportMenuOptionsConstants';
import { DOCTOR_ROUTES, USER_REGION, completed, incoming, pending } from 'constants/app';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModalScreen from 'screens/Ecgscreens/ConfirmationModalScreen';
import { getAcuity, getBaseRythmData, reviewSubmit, submitPatientReportId } from 'store/actions';
import {
  getQuality,
  setDefaultSubmitStatus,
  updateReportStatus,
} from 'store/actions/EkgReviewActions';
import { useParams } from 'react-router';
import RenderSelectDropdown from 'components/mui/formElements/RenderSelectDropdown';
import EcgHeader from 'screens/Ecgscreens/Ecgheader';
import CustomModal from 'components/Modals/CustomModal';
import WarningIcon from 'assets/images/Vector.png';
import TitleAlert from 'components/Shared/Alert/TitleAlert';
import { Toast } from 'react-bootstrap';
import { EKG_EMOJI } from 'assets/assetPath/constant';
import theme from '../../../screens/Ecgscreens/Theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ecgTablePageNumber } from 'store/actions/EkgListTableActions';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '368px',
  padding: '16px',
  borderRadius: '16px',
  background: '#FFF',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  backdropFilter: 'blur(8px)',
};

const ECGreview = (props) => {
  const { reviewStatus } = useParams();
  const isSuccessRef = useRef();
  const dispatch = useDispatch();

  //Props destructuring
  const {
    patientData,
    currentReviewId,
    aiDeterminationDetails,
    interpretation,
    handleNext,
    handleprevious,
    symptoms,
    isAdmin,
    isPendingmodalOpen,
    totalPaginationCount,
    fetchEcgData,
  } = props;

  // Data from redux Store
  const isSuccessSubmittingReview = useSelector((state) => state.ekgReview.isReviewSubmitted);
  isSuccessRef.current = isSuccessSubmittingReview;
  // const formattedDate = `${getDateFromString(patientData?.dob)} `;
  const formattedDate = `${formatDate(patientData?.dob)} `;
  const FormattedAge = `${ageCalculateFromString(patientData?.dob)}`;
  const isUsRegion = localStorage.getItem(CRP_REGION) === USER_REGION;
  const history = useHistory();
  const { isSubmittingReview, reviewErrorMessage } = useSelector((state) => state.ekgReview);
  const { reviewFieldUpdated } = useSelector((state) => state.ekgReview);
  const incominglistData = useSelector((state) => state.ekgListTable.ecgReviewPaginationArray);
  const profile = useSelector((state) => state.user.profile);
  const { timeSubmit } = useSelector((state) => state.ekgReview?.reviewDetail);
  const userCountry = useSelector((state) => state.user.profile.country);
  // Component level state
  const [errorMessage, setErrorMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const isRails409ErrorRef = useRef();
  isRails409ErrorRef.current = reviewErrorMessage;
  const [isNetworkError, setIsNetworkError] = useState(false);
  const timePart = patientData?.recordedAt && patientData?.recordedAt.split('T')[1].slice(0, -2);
  const [isSubmit, setIsSubmit] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({
    pvcData: '',
    pacData: '',
    qrsData: '',
  });

  const [secondaryDropDownOptions, setSecondaryDropDownOptions] = useState({
    rhythmId: '',
    acuityId: '',
    qualityId: '',
    notes: '',
  });

  // ============ Av blocks ===================//
  const [avblocks, setAvBlocks] = useState({
    none: false,
    seconddegreeone: false,
    firstdegree: false,
    thirddegree: false,
    seconddegeentwo: false,
  });

  useEffect(() => {
    dispatch(getBaseRythmData());
    dispatch(getAcuity());
    dispatch(getQuality());
    if (reviewStatus === incoming) {
      dispatch(updateReportStatus(props.patientId, 'inProgress'));
    }
  }, []);

  const handleAvBlocksChange = (avBlocks) => {
    setAvBlocks(avBlocks);
  };

  const mapAvBlocksValue = (av) => {
    switch (av) {
      case 'firstdegree':
        return ECG_review_page.FIRST_DEGREE;
      case 'seconddegreeone':
        return ECG_review_page.SECOND_DEGREE_ONE;
      case 'thirddegree':
        return ECG_review_page.THIRD_DEGREE;
      case 'seconddegeentwo':
        return ECG_review_page.SECOND_DEGREE_TWO;
      case 'none':
        return ECG_review_page.NONE;
      default:
        return '';
    }
  };

  // ============ ends avblock=============//

  const maplanguagevalue = (language) => {
    switch (language) {
      case 'fr':
        return ECG_review_page.FRENCH;
      case 'sv':
        return ECG_review_page.SWEDISH;
      case 'en':
        return ECG_review_page.ENGLISH;
      case 'fi-FI':
        return ECG_review_page.FINNISH;
      case 'vi':
        return ECG_review_page.VIETNAMESE;
      case 'en-gb':
        return ECG_review_page.ENGLISH;
      case 'en-US':
        return ECG_review_page.ENGLISH;
      default:
        return '';
    }
  };

  const mapsexData = (s) => {
    switch (s) {
      case 'M':
        return ECG_review_page.M;
      case 'F':
        return ECG_review_page.F;
      case 'I_prefer_not_to_say':
        return ECG_review_page.IPREFERNOTTOSAY;
      default:
        return '';
    }
  };

  // It will hide the Modal after network error goes off
  const hideModalNetwork = () => {
    setIsNetworkError(false);
  };
  const retryHandler = () => {
    setIsNetworkError(false);
    setTimeout(() => {
      handleSubmit();
    }, 300);
  };

  function handleSubmit() {
    setIsSubmit(1);
    if (
      selectedOptions?.pvcData === '' ||
      selectedOptions?.pacData === '' ||
      selectedOptions?.qrsData === '' ||
      secondaryDropDownOptions?.qualityId === '' ||
      secondaryDropDownOptions?.rhythmId === '' ||
      secondaryDropDownOptions?.acuityId === '' ||
      secondaryDropDownOptions?.notes === '' ||
      !Object.values(avblocks)
        .map((val) => (val ? true : false))
        .includes(true)
    ) {
      // Dispatch action indicating error
      setErrorMessage(ECG_review_page.ERRORMESSAGE);
      return;
    }
    //This will handle submissions on Offline mode(Throws error)
    if (!window.navigator.onLine) {
      // setIsSubmittingReviewFailed(false);
      setIsNetworkError(true);
      return;
    }

    dispatch(submitPatientReportId(props.reportIDdata));
    let avBlockValues = [];
    Object.keys(avblocks).forEach((item) => {
      if (avblocks[item] === true) {
        avBlockValues.push(mapAvBlocksValue(item));
      }
    });
    const review = {
      patientId: parseInt(props.patientId),
      memberFirstName: profile.firstName,
      memberLastName: profile.lastName,
      quality: secondaryDropDownOptions.qualityId,
      rhythmId: secondaryDropDownOptions.rhythmId,
      acuityId: secondaryDropDownOptions.acuityId,
      notes: secondaryDropDownOptions.notes,
      pvcs: selectedOptions.pvcData,
      pacs: selectedOptions.pacData,
      qrs: selectedOptions.qrsData,
      avBlocks: avBlockValues,
    };

    if (!isSubmittingReview) {
      dispatch(reviewSubmit(review));
    }
  }

  useEffect(() => {
    if (isSuccessSubmittingReview) {
      reviewStatus === incoming &&
        setTimeout(() => {
          handleNext();
        }, 3000);
      // Call handleNext directly
      reviewStatus === pending &&
        setTimeout(() => {
          history.push(DOCTOR_ROUTES.TELEKARDIA_ECG);
        }, 5000);
    }
  }, [isSuccessSubmittingReview]);

  useEffect(() => {
    return () => {
      if (isSuccessRef.current) {
        dispatch(setDefaultSubmitStatus());
        return;
      }

      dispatch(setDefaultSubmitStatus());
      reviewStatus === incoming && dispatch(updateReportStatus(props.patientId, 'cancel'));
    };
  }, []);

  // handleCancle -> To go back to Incoming/Completed when user clicks on arrow else part to hanndle back navigation on complete screen
  const [pendingReviewNotSaved, setPendingReviewNotSaved] = useState(false);
  const handleCancle = () => {
    if (reviewStatus === incoming) {
      setModalOpen(true);
    } else {
      reviewFieldUpdated
        ? setPendingReviewNotSaved(true)
        : history.push(DOCTOR_ROUTES.TELEKARDIA_ECG);
    }
  };

  // ========= ASP-402 ============== //
  const [paginationDirection, setPaginationDirection] = useState({ left: false, right: false });

  const nextRequestNavigation = useCallback(() => {
    if (reviewFieldUpdated) {
      setPaginationDirection({ left: false, right: true });
      setPendingReviewNotSaved(true);
    } else handleNext();
  }, [reviewFieldUpdated]);

  const previousRequestNavigation = useCallback(() => {
    if (reviewFieldUpdated) {
      setPaginationDirection({ left: true, right: false });
      setPendingReviewNotSaved(true);
    } else handleprevious();
  }, [reviewFieldUpdated]);
  // ============= end ================ //

  // handleredirected to Completed list table
  const handleredirection = useCallback(() => {
    paginationDirection.left
      ? handleprevious()
      : paginationDirection.right
      ? handleNext()
      : history.push(DOCTOR_ROUTES.TELEKARDIA_ECG);
  }, [paginationDirection]);

  // To make user to stay on the same page state is setting  back to false
  const handlepageconfirmation = () => {
    setModalOpen(false);
  };

  // ============== when pending review time period is over =============== //
  const [isPendingReviewTimeover, setisPendingReviewTimeover] = useState(false);

  const handlIsPendingReviewTimeoverWindow = () => {
    setPendingReviewNotSaved(false);
    setisPendingReviewTimeover(!isPendingReviewTimeover);
  };
  // ========= end ========= //
  const ecgpageNumberValue = useSelector((state) => state.ekgListTable.ecgTablePageNumber);

  useEffect(() => {
    if (currentReviewId >= incominglistData.length - 1) {
      dispatch(ecgTablePageNumber(ecgpageNumberValue + 1));
      fetchEcgData();
    }
  }, [currentReviewId]);

  return (
    <>
      {isSuccessSubmittingReview !== 0 && reviewStatus === incoming && (
        <TitleAlert
          style={{ background: 'green' }}
          title={`Report ${props.patientId} has been successfully submitted.`}
        />
      )}
      {isSuccessSubmittingReview !== 0 && reviewStatus === pending && (
        <TitleAlert
          style={{ background: 'green' }}
          title={`Report ${props.patientId} has been successfully resubmitted.`}
        />
      )}
      <Box>
        {modalOpen && (
          <ConfirmationModalScreen
            handleredirection={handleredirection}
            handlepageconfirmation={handlepageconfirmation}
          />
        )}
        <SectionMain
          fullWidth
          style={{
            border: '1px solid var(--light-other-outlined-border-23-p, rgba(0, 0, 0, 0.23))',
            background: 'var(--Grey-100, #F5F5F5)',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          }}
          headerElement={{
            icon: <BackArrowIcon style={{ marginTop: '4px' }} onClick={handleCancle} />,
            headerText: `${patientData.lastName}, ${patientData.firstName}`,
            action: (
              <Box>
                {reviewStatus === completed ? (
                  <Box style={{ marginLeft: 'auto' }}>
                    <Typography display={'flex'} justifyContent={'flex-end'} columnGap={3}>
                      <Typography
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                        {' '}
                        {currentReviewId} of {totalPaginationCount}{' '}
                      </Typography>
                      <Typography>
                        {/* This condition is wriiten to handle the arrows */}
                        {currentReviewId === 1 ? (
                          <img src={EKG_EMOJI.BACKWARD_ARROW} alt="backArrow" />
                        ) : (
                          <img
                            onClick={() => {
                              handleprevious();
                            }}
                            src={EKG_EMOJI.BACKWARD_ARROW}
                            alt="backArrow"
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                        {currentReviewId === totalPaginationCount ? (
                          <img src={EKG_EMOJI.FORWARD_ARROW} alt="Image" />
                        ) : (
                          <img
                            onClick={() => {
                              handleNext();
                            }}
                            src={EKG_EMOJI.FORWARD_ARROW}
                            alt="Image"
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </Typography>
                    </Typography>
                  </Box>
                ) : reviewStatus === pending ? (
                  <Box style={{ marginLeft: 'auto', display: 'flex' }}>
                    <Box>
                      {' '}
                      <Typography display={'flex'} justifyContent={'flex-end'}>
                        <Typography
                          style={{
                            marginLeft: '10px',
                          }}
                        >
                          {' '}
                          {currentReviewId} of {incominglistData && incominglistData.length}{' '}
                        </Typography>
                        <Typography>
                          {currentReviewId === 1 ? (
                            <img src={EKG_EMOJI.BACKWARD_ARROW} alt="Image" />
                          ) : (
                            <img
                              onClick={() => {
                                handleprevious();
                              }}
                              src={EKG_EMOJI.BACKWARD_ARROW}
                              alt="Image"
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                          {currentReviewId === incominglistData.length ? (
                            <img src={EKG_EMOJI.FORWARD_ARROW} alt="Image" />
                          ) : (
                            <img
                              onClick={() => {
                                handleNext();
                              }}
                              src={EKG_EMOJI.FORWARD_ARROW}
                              alt="Image"
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ),
            childElements: (
              <Stack direction={'row'} columnGap={1} sx={{ padding: '0px 27px' }} gap={'24px'}>
                <PatientChartscreen lable={ECG_review_page.MRNVALUE} value={'--'} />
                <PatientChartscreen
                  lable={ECG_review_page.DATEOFBIRTH}
                  value={patientData?.dob ? formatDate(formattedDate, userCountry) : '--'}
                />
                <PatientChartscreen lable={ECG_review_page.AGE} value={FormattedAge} />
                <PatientChartscreen
                  lable={ECG_review_page.SEX}
                  value={patientData?.sex ? mapsexData(patientData?.sex) : 'NA(TBD)'}
                />
                <PatientChartscreen
                  lable={ECG_review_page.HEIGHT}
                  value={
                    patientData?.height
                      ? isUsRegion
                        ? cmToFeet(patientData?.height)
                        : `${(patientData?.height / 100).toPrecision(2)} m`
                      : '--'
                  }
                />
                <PatientChartscreen
                  lable={ECG_review_page.WEIGHT}
                  value={
                    patientData?.weight
                      ? isUsRegion
                        ? `${Math.floor(patientData?.weight * 2.2046)} lbs`
                        : `${patientData?.weight} kg`
                      : '--'
                  }
                />
              </Stack>
            ),
          }}
          bodyElement={[
            {
              childElements: (
                <Grid container>
                  <EcgHeader
                    currentReviewId={currentReviewId}
                    handleNext={() => nextRequestNavigation()}
                    handleprevious={() => previousRequestNavigation()}
                    aiDeterminationDetails={interpretation}
                    devicedetails={patientData?.device}
                    heartrate={patientData.heartRate}
                    navigateBack={handlIsPendingReviewTimeoverWindow}
                  />
                </Grid>
              ),
            },
            {
              childElements: (
                <Grid container spacing={2} sx={{ borderRadius: '16px !important' }}>
                  <Grid item lg={4} md={6} sm={12}>
                    <SectionBody
                      sx={{ backgroundColor: '#fff !important', borderRadius: '12px !important' }}
                      headerText={'ECG Details'}
                      childElements={
                        <Stack columnGap={0}>
                          <SkeletonLoader>
                            <Patientdetailsdata
                              labledata="AI Determination:"
                              keydata={aiDeterminationDetails}
                            />
                            <Patientdetailsdata
                              labledata="Report ID:"
                              keydata={patientData?.reportId}
                            />
                            <Patientdetailsdata
                              labledata="Heart Rate:"
                              keydata={
                                patientData?.heartRate > 0 ? patientData?.heartRate + ' bpm' : '--'
                              }
                            />
                            <Patientdetailsdata
                              labledata="Device:"
                              keydata={
                                patientData?.device ? toPascalCaseString(patientData?.device) : '--'
                              }
                            />
                            <Patientdetailsdata
                              labledata="Date Recorded:"
                              keydata={
                                patientData?.recordedAt
                                  ? `${formatDate(
                                      patientData?.recordedAt,
                                      userCountry,
                                    )} ${timeFormat(patientData?.recordedAt)}`
                                  : null
                              }
                            />
                          </SkeletonLoader>
                        </Stack>
                      }
                    />

                    <SectionBody
                      sx={{ backgroundColor: '#fff !important', borderRadius: '12px !important' }}
                      headerText="Patient Details"
                      childElements={
                        <Stack columnGap={0}>
                          <SkeletonLoader>
                            <Patientdetailsdata
                              labledata="Notes:"
                              keydata={patientData?.patientNotes}
                            />
                            <Patientdetailsdata labledata="Symptoms:" keydata={symptoms} />
                            <Patientdetailsdata
                              labledata="Language:"
                              keydata={
                                patientData?.language
                                  ? maplanguagevalue(patientData?.language)
                                  : null
                              }
                            />
                          </SkeletonLoader>
                        </Stack>
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <Secondaryobervationscreen
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                      handleAvBlocksChange={handleAvBlocksChange}
                      avblocks={avblocks}
                      setAvBlocks={setAvBlocks}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <SectionBody
                      sx={{ backgroundColor: '#fff !important', borderRadius: '12px !important' }}
                      childElements={
                        <Stack direction={'row'} columnGap={1} sx={{ width: '100%' }}>
                          <SkeletonLoader
                          // isloading={loading || progressLoading || error !== null}
                          >
                            <RenderSelectDropdown
                              setSecondaryDropDownOptions={setSecondaryDropDownOptions}
                              secondaryDropDownOptions={secondaryDropDownOptions}
                              errorMessage={errorMessage}
                            />
                          </SkeletonLoader>
                        </Stack>
                      }
                    />
                    {reviewStatus === incoming || reviewStatus === pending ? (
                      <Grid container spacing={1}>
                        <Grid item lg={6} md={6} sm={12} columnGap={1}>
                          <Button fullWidth variant="outlined" onClick={handleCancle}>
                            CANCEL
                          </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} columnGap={1}>
                          <Button
                            style={{ background: 'var(--Light-Primary-Main, #6387C5)' }}
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                          >
                            {reviewStatus === incoming ? `SUBMIT and NEXT` : `RESUBMIT`}
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Button fullWidth variant="contained" onClick={handleredirection}>
                        Close
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ),
            },
          ]}
        />

        {modalOpen && (
          <ConfirmationModalScreen
            heading={ECG_review_confirmationmodal.REVIEW_NOTSUBMITTED}
            Description={ECG_review_confirmationmodal.ARE_YOU_SURE_YOU_WANTTOLEAVE}
            Descriptioninformation={ECG_review_confirmationmodal.PROGRESS_DESCRIPTION}
            handleredirection={handleredirection}
            handlepageconfirmation={handlepageconfirmation}
          />
        )}

        {isPendingmodalOpen && (
          <ConfirmationModalScreen
            heading={ECG_review_confirmationmodal.CHANGES_NOT_SAVED}
            Description={ECG_review_confirmationmodal.ARE_YOU_SURE_YOU_WANTTOLEAVE}
            Descriptioninformation={ECG_review_confirmationmodal.PENDING_DESCRIPTION}
            handleredirection={handleredirection}
            handlepageconfirmation={handlepageconfirmation}
          />
        )}

        <CustomModal
          modalType="confirm"
          open={isNetworkError}
          closeModalHandler={hideModalNetwork}
          icon={WarningIcon}
          iconAltText="Warning Icon"
          heading="Network Issue"
          descriptionLine1="There was an issue when you attempted to submit the report."
          descriptionLine2="Please check your internet connection and then try again."
          confirmHandler={retryHandler}
          confirmButtonText="TRY AGAIN"
          declineButtonText="CANCEL"
        />

        {isPendingReviewTimeover || pendingReviewNotSaved ? (
          <ThemeProvider theme={theme}>
            (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={isPendingReviewTimeover || pendingReviewNotSaved}
              onClose={handlIsPendingReviewTimeoverWindow}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={isPendingReviewTimeover || pendingReviewNotSaved}>
                <Box sx={style}>
                  <Typography
                    sx={{ mt: 1, fontFamily: 'Work Sans', fontSize: 20, fontWeight: 600 }}
                    component="h2"
                  >
                    {pendingReviewNotSaved ? (
                      <Typography variant="h5">Changes Have Not Been Saved</Typography>
                    ) : (
                      `Confirm`
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      fontFamily: 'Work Sans',
                      fontSize: 16,
                      color: 'rgba(0, 0, 0, 0.6)',
                    }}
                  >
                    {pendingReviewNotSaved ? (
                      <>
                        <Box>
                          <Typography variant="customvarient">
                            Are you sure you want to leave?
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="customvarient">
                            {' '}
                            If you do, the current selections will not be saved.
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      `Pending request's 5 mins window is over. You can not edit this request. `
                    )}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginTop: '25px',
                      gap: 3,
                    }}
                  >
                    {pendingReviewNotSaved ? (
                      <Button variant="outlined" onClick={() => setPendingReviewNotSaved(false)}>
                        NO, CANCEL
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      onClick={handleredirection}
                      style={{ background: 'var(--Light-Primary-Main, #6387C5)' }}
                    >
                      {pendingReviewNotSaved ? `YES, LEAVE` : `Redirect to Completed Worklist`}
                    </Button>
                  </div>
                </Box>
              </Fade>
            </Modal>
            ){' '}
          </ThemeProvider>
        ) : null}
      </Box>
    </>
  );
};

export default ECGreview;
