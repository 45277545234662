import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Strophe, $pres, $msg } from 'strophe.js/src/strophe.js';
import moment from 'moment';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

//utilities
export function getFullJid(jid) {
  let { hostname } = new URL(process.env.REACT_APP_CHAT_DOMAIN);
  return `${jid}@${hostname}`;
}

const options = {
  mechanisms: [Strophe.SASLMD5],
};
const CHAT_ENDPOINT = `${process.env.REACT_APP_CHAT_API_BASE}bosh`;
const LOCAL_STORAGE_UNREAD_MSG_KEY = '_um';
const LOCAL_STORAGE_INBOX_HISTORY_KEY = '_ih';
const LOCAL_STORAGE_CHAT_USER_ID = '_id';

const useAcStrophe = (userId, password) => {
  /**
   * Status - Status of the user trying to connect to chat server
   * recStatus - Status of the reciever (*Need prescence to be enabled on both sides)
   * typing - typing Status of the reciever (*Need prescence to be enabled on both sides)
   * messagePoolObject - History
   */
  var connection = useRef(null);
  const [status, setStatus] = useState(false);
  const [recStatus, setRecStatus] = useState(false);
  const [typing, setTyping] = useState(false);
  const [unreadMsgCount, setUnreadMsgCount] = useState({});
  const [messagePoolObject, setMessagePoolObject] = useState({});
  const messagePoolObjectRef = React.useRef({});
  const [scrollWayUp, setScrollWayUp] = useState(false);
  const [currentAppointmentUser, setCurrentAppointmentUser] = useState(null);
  const [currentChatUser, setCurrentChatUser] = useState(null);

  //listen to unread message and push to local storage
  useEffect(() => {
    if (Object.keys(unreadMsgCount).length > 0) {
      console.log(
        'DIAGNOSTICS: set unread message counts in secure local storage - ',
        unreadMsgCount,
      );
      secureLocalStorage.setItem(LOCAL_STORAGE_UNREAD_MSG_KEY, JSON.stringify(unreadMsgCount));
    }
  }, [unreadMsgCount]);

  //listen to inbox history changes and push to local storage
  useEffect(() => {
    if (Object.keys(messagePoolObject).length > 0) {
      secureLocalStorage.setItem(
        LOCAL_STORAGE_INBOX_HISTORY_KEY,
        JSON.stringify(messagePoolObject),
      );
    }
  }, [messagePoolObject]);

  //listen to state change and save chat user id
  useEffect(() => {
    if (status === Strophe.Status.CONNECTED) {
      const existingChatUserId = secureLocalStorage.getItem(LOCAL_STORAGE_CHAT_USER_ID);

      if (existingChatUserId && existingChatUserId != userId) {
        //remove old chat user's cache
        secureLocalStorage.removeItem(LOCAL_STORAGE_INBOX_HISTORY_KEY);
        secureLocalStorage.removeItem(LOCAL_STORAGE_UNREAD_MSG_KEY);
        secureLocalStorage.setItem(LOCAL_STORAGE_CHAT_USER_ID, userId);
      }
    }
  }, [status]);

  /**
   * load inbox history and msg count from local storage if any
   * NOTE: this does not work if we open a change browser or clear
   * cache/storage in existing browser for the site as we use local storage
   **/
  useEffect(() => {
    let msgCount = JSON.parse(secureLocalStorage.getItem(LOCAL_STORAGE_UNREAD_MSG_KEY));
    let inboxHistory = JSON.parse(secureLocalStorage.getItem(LOCAL_STORAGE_INBOX_HISTORY_KEY));

    if (inboxHistory) {
      setMessagePoolObject(inboxHistory);
    }
    if (msgCount) {
      console.log('DIAGNOSTICS: get unread message counts from local storage - ', msgCount);

      setUnreadMsgCount(msgCount);
    }
  }, []);

  const retryConnection = (userId, password) => {
    console.log('retrying connection');
    connectToChatServer(getFullJid(userId), password);
  };
  const closeConnection = () => {
    console.log('DISCONNECTING CHAT USER');
    if (connection.current) {
      connection.current.disconnect();
    }
  };
  const pres = useRef(null);
  const rec = useRef(null);
  const deleteAllHandlers = () => {
    if (pres.current && rec.current) {
      connection.current.deleteHandler(pres);
      connection.current.deleteHandler(rec);
    }
  };

  useEffect(() => {
    //trying to connect with chat server
    if (userId && password) {
      connectToChatServer(getFullJid(userId), password);
    }
    return () => {
      if (connection.current) {
        connection.current.reset();
        console.log('CLEANING UP HANDLERS');
        deleteAllHandlers();
      }
    };
  }, [userId, password]);

  useEffect(() => {
    if (currentChatUser) {
      unreadMsgCount[currentChatUser] &&
        setUnreadMsgCount((d) => {
          d[currentChatUser] = 0;
          return d;
        });
    }
  }, [messagePoolObject]);

  /**
   * @name onConnect
   * @desc Callback function to notify user connection status.
   * @param {*} status
   */

  const onConnect = function (status) {
    console.log('Diagnostics: Chat server status - ', status);
    setStatus(status);
    if (status === Strophe.Status.CONNECTED) {
      console.log('Diagnostics: Chat server is connected');
      connection.current.send($pres());
      rec.current = connection.current.addHandler(
        onMessageRecieved,
        null,
        'message',
        null,
        null,
        null,
      );
      pres.current = connection.current.addHandler(onPresence, null, 'presence');
    }
  };

  /**
   * @name connectToChatServer
   * @desc Connecting to chat server
   * @param {*} jid
   * @param {*} password
   */
  const connectToChatServer = (jid, password) => {
    console.log('CONNECTING TO CHAT SERVER');
    connection.current = new Strophe.Connection(CHAT_ENDPOINT, options);
    connection.current.connect(`${jid}/web`, password, onConnect);
  };

  /**
   * @name connectToChatServer
   * @desc Getting the prescence of the reciever
   * @param {*} presence
   */
  function onPresence(presence) {
    var presence_type = presence.getAttribute('type'); // unavailable, subscribed, etc...
    // var from = presence.getAttribute("from"); // the jabber_id of the contact
    if (!presence_type) {
      presence_type = 'online';
      setRecStatus('Online');
    }
    if (presence_type !== 'error') {
      if (presence_type === 'unavailable') {
        // Mark contact as offline
        setRecStatus('Offline');
      } else {
        var show = presence.getElementsByTagName('show');
        const showText = show.length > 0 ? Strophe.getText(show[0]) : '';
        // this is what gives away, dnd, etc.
        if (showText === 'chat' || showText === '') {
          setRecStatus('Online');
          // Mark contact as online
        } else {
          // etc...
          setRecStatus(showText);
        }
      }
    }
    return true;
  }

  /**
   * @name log
   * Log the message onto the screen
   * @param {*} status
   */
  function updateMessagePool(source, text, timestamp, isOwn = false) {
    const [from] = source.split('@');
    //using ref for comparison in a handler

    if (
      !messagePoolObjectRef.current[from] ||
      messagePoolObjectRef.current[from].time != parseInt(timestamp)
    ) {
      // console.log(messagePoolObject[from], timestamp);
      // console.log(messagePoolObjectRef.current);
      // console.log('UPATE POOL');
      messagePoolObjectRef.current[from] = {
        source: source,
        text: text,
        time: parseInt(timestamp),
        isOwn,
      };
      setMessagePoolObject((msgs) => {
        let result = { ...msgs };
        // const [source, text, timestamp] = msg.split(':')

        result[from] = {
          source: source,
          text: text,
          time: parseInt(timestamp),
          isOwn,
        };
        return result;
      });
    }
  }

  /**
   * Message Handler
   * trigger when someone send us message.
   * @param {*} msg
   * @returns
   */
  function onMessageRecieved(msg) {
    const from = msg.getAttribute('from');
    const isModUser = from.toString().includes('_mod');
    const type = msg.getAttribute('type');

    if (type && !isModUser) {
      //catching bosh call for inbox related messages
      const elems = msg.getElementsByTagName('body');
      const composing = msg.getElementsByTagName('composing');
      const paused = msg.getElementsByTagName('paused');
      if (Object.values(composing).length > 0) setTyping(true);
      if (Object.values(paused).length > 0) setTyping(false);
      if (type === 'chat' && elems.length > 0) {
        var body = elems[0];
        console.log('CHAT: I got a message from ' + from + ': ' + Strophe.getText(body));
        let bareJid = Strophe.getBareJidFromJid(from.toLowerCase()).split('@')[0];
        let resourceId = Strophe.getResourceFromJid(from.toLowerCase());
        setUnreadMsgCount((prev) => ({
          ...prev,
          [bareJid]: prev[bareJid] ? prev[bareJid] + 1 : 1,
        }));
        updateMessagePool(from, Strophe.getText(body), Date.now());
        setScrollWayUp(false);
        setTyping(false);
      }
    } else if (isModUser && msg.getElementsByTagName('delay').length == 0) {
      //catching mod bosh call used for waiting room logic
      const elems = msg.getElementsByTagName('body');
      const dataFromMod =
        elems.length > 0 && JSON.parse(Strophe.getText(elems[0]).replace(/&quot;/g, '"'));

      dataFromMod && setCurrentAppointmentUser(dataFromMod);
    }
    // we must return true to keep the handler alive.
    // returning false would remove it after it finishes.
    return true;
  }

  /**
   * Fetch chat history based on user id
   * @param {*} jid - your id
   * @param {*} withJid - to whom you want chat history user id
   * @param {*} messageCount - no. of messages
   * @param {*} callback
   */
  const fetchAllChatHistory = async (jid, withJid, messageCount = 1, callback) => {
    connection.current.mam.query(Strophe.getBareJidFromJid(jid), {
      with: withJid,
      before: '',
      max: messageCount,
      onMessage: function (message) {
        // console.log('MESSAGE', message);
        let messageDetails = message.getElementsByTagName('message')[0];
        let [from] = messageDetails.getAttribute('from').split('@');
        let to = messageDetails.getAttribute('to');
        // let type = messageDetails.getAttribute("type");
        let messageText = message.getElementsByTagName('body')[0]?.textContent;
        let timestamp = messageDetails.getElementsByTagName('stanza-id')[0].getAttribute('id');
        // let msgId = messageDetails.getAttribute("id");
        const result = { ...messagePoolObject };
        const chatUser = from === userId ? to : from;
        const isOwn = from === userId;
        result[chatUser] = {
          source: chatUser,
          text: messageText,
          time: new Date(Math.round(timestamp / 1000)),
        };
        // console.log(messageText);
        updateMessagePool(getFullJid(chatUser), messageText, timestamp / 1000, isOwn);
        callback();
        return true;
      },
      onComplete: function (response) {
        //can use this response to find the ids of the first message and the last message
        // also gives the count
        // console.log(response);
        // console.log('COMPLETE');
      },
    });
  };

  return {
    status,
    recStatus,
    typing,
    messagePoolObject,
    messagePoolObjectRef,
    connection,
    currentAppointmentUser,
    currentChatUser,
    userId,
    password,
    unreadMsgCount,
    setCurrentAppointmentUser,
    fetchAllChatHistory,
    setCurrentChatUser,
    setUnreadMsgCount,
    retryConnection,
    closeConnection,
    updateMessagePool,
    scrollWayUp,
    setScrollWayUp,
  };
};

export const InitChatWithUser = (chatUserId) => {
  const {
    status,
    recStatus,
    typing,
    messagePoolObject,
    connection,
    currentAppointmentUser,
    setCurrentChatUser,
    updateMessagePool,
    fetchAllChatHistory,
    unreadMsgCount,
    setUnreadMsgCount,
    userId,
    password,
    retryConnection,
    scrollWayUp,
    setScrollWayUp,
  } = useContext(ChatContext);

  const [messages, setMessages] = useState({});
  const [rawMessages, setRawMessages] = useState([]);
  const [chatFirstMessage, setChatFirstMessage] = useState();
  const [fetchingHistory, setFetchingHistory] = useState(false);
  const [myMessage, setMyMessage] = useState(null);

  //setting latest message of the current chatting user.
  useEffect(() => {
    if (messagePoolObject) {
      if (messagePoolObject[chatUserId]) {
        if (!myMessage) {
          setMyMessage(messagePoolObject[chatUserId]);
        } else if (myMessage && messagePoolObject[chatUserId].time != myMessage.time) {
          setMyMessage(messagePoolObject[chatUserId]);
        }
      }
    }
  }, [messagePoolObject]);
  // const myMessage = messagePoolObject && messagePoolObject[chatUserId];
  useEffect(() => {
    if (status == 6) {
      console.log('RETRYING CONNECTION!');
      retryConnection(userId, password);
    }
  }, [status]);

  useEffect(() => {
    setChatFirstMessage();
    setMessages({});
    setCurrentChatUser(chatUserId);
    setScrollWayUp(false);
    return () => setCurrentChatUser(null);
  }, [chatUserId]);

  //catching incoming messages and adding them to the sender's chat pool
  useEffect(() => {
    if (myMessage && !myMessage.isOwn) {
      console.log('DIAGNOSTICS: unread message counts member wise - ', unreadMsgCount[chatUserId]);
      console.log('MESSAGE COMING IN!');
      setMessages((prev) => messageGroupByDay(prev, myMessage));
    }
    setUnreadMsgCount((prev) => ({ ...prev, [chatUserId]: 0 })); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myMessage, chatUserId]);

  //group messages from an array into an object{timestamp: array[]} day wise
  const messageGroupByDay = (prevMessages, currentMessage) => {
    let timeStampKey = moment(new Date(currentMessage['time'])).startOf('day');
    return {
      ...prevMessages,
      [timeStampKey]: prevMessages[timeStampKey]
        ? [...prevMessages[timeStampKey], currentMessage]
        : [currentMessage],
    };
  };

  //sort message in each specific day based on timestamp (safety check)
  function sortMessagesByDay(msgs) {
    let retVal = {};
    let m = Object.keys(msgs);
    m.sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
    m.map((c) => {
      msgs[c].sort(function (a, b) {
        return new Date(a.time) - new Date(b.time);
      });
      return (retVal[c] = msgs[c]);
    });
    return retVal;
  }

  //convert incoming xml into json format
  const convertMessageXMLtoJson = (message) => {
    let messageDetails = message.getElementsByTagName('message')[0];
    let from = messageDetails.getAttribute('from');
    let messageText = message.getElementsByTagName('body')[0]?.textContent;
    let msgId = messageDetails.getAttribute('id');
    let timestamp = messageDetails.getElementsByTagName('stanza-id')[0].getAttribute('id');
    return {
      source: from,
      text: messageText.toString(),
      time: timestamp / 1000,
      id: msgId,
      status: 2,
    };
  };

  /**
   *
   * @param {*} sourceUserId the coach id
   * @param {*} targetChatUserId the chat target user id
   * @param {*} size no of messages
   * @param {*} lastMessageId nth message id
   * @param {*} token jwt
   */

  const getHistoryFromTk = useCallback(
    (
      sourceUserId,
      targetChatUserId,
      size,
      lastMessageId,
      token,
      oldMessages,
      oldMessagesGroupedByDay,
    ) => {
      setFetchingHistory(true);
      axios
        .get(
          `${process.env.REACT_APP_TELEKARDIA_API_BASE}api/v1/chat/history/${sourceUserId}?targetChatUserId=${targetChatUserId}&size=${size}&lastMessageId=${lastMessageId}`,
          {
            headers: { Authorization: ` Bearer ${token}` },
          },
        )
        .then((res) => {
          if (res.status == 200) {
            let resultData = res.data.result;
            // console.log('RESULT FROM TK', resultData)
            /**
             * Format of the message from tk is-
             * body: "dev23"
             * from: "kgdv6gvzz6fn94k9ihbzchwcx2zlpcnb_emp@us-chat-kardia-ejabberd-k8s.development.alivecor.net/aragavender865-mbp"
             * id: "1649831323386426"
             * timestamp: 1649831323386
             * to: "hlomxofsj5zltnhnu5cvchwehg668pu8_coa@us-chat-kardia-ejabberd-k8s.development.alivecor.net"
             * type: "chat"
             */
            console.log('OLD MESSAGES', oldMessages);
            console.log('OLD MESSAGES BY DAY', oldMessagesGroupedByDay);
            let msgs = oldMessages;
            let msgsGroupedByDay = oldMessagesGroupedByDay;
            if (resultData.length > 0) {
              resultData.map((r) => {
                const { id, from, body, timestamp } = r;
                let timeStampKey = moment(new Date(timestamp)).startOf('day');
                const currentMessage = {
                  source: from,
                  text: body.toString(),
                  time: timestamp,
                  id,
                  status: 2,
                };
                msgsGroupedByDay = {
                  ...msgsGroupedByDay,
                  [timeStampKey]: msgsGroupedByDay[timeStampKey]
                    ? [...msgsGroupedByDay[timeStampKey], currentMessage]
                    : [currentMessage],
                };
                msgs.push(currentMessage);
              });
              let sortedMessages = sortMessagesByDay(msgsGroupedByDay);
              if (msgs.length > size) {
                setScrollWayUp(true);
              }
              setRawMessages(msgs);
              setMessages(sortedMessages);
            }

            setFetchingHistory(false);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [connection, rawMessages],
  );

  //This is from storphe plugins documentation
  //[https://github.com/strophe/strophejs-plugin-receipts/blob/receipts/strophe.receipts.js]

  function newSendMessage(connection, msg) {
    var id = connection.current.getUniqueId();

    msg.tree().setAttribute('id', id);
    connection.current.send(msg);
    return id;
  }
  /**
   * Send message
   * @param {*} msg  - content you want to send
   * @param {*} senderId - sender id
   * @param {*} recieverId - receiver Id
   */
  async function sendMessage(msg, senderId, recieverId) {
    console.log('sending msg');
    console.log(msg, senderId, recieverId);
    var m = $msg({
      to: recieverId.toLowerCase(),
      from: senderId.toLowerCase(),
      type: 'chat',
    })
      .c('body')
      .t(msg);

    //FIX: using sendMessage custom function from documentation to comment out resendMessage()
    // const msgId = connection.receipts.sendMessage(m)
    const msgId = newSendMessage(connection, m);
    updateMessagePool(recieverId, msg, Date.now(), true); //this is just for dashboard
    setScrollWayUp(false);
    setMessages((prev) =>
      messageGroupByDay(prev, {
        source: senderId.toLowerCase(),
        text: msg,
        time: Date.now(),
        id: msgId,
        status: 'Sent',
      }),
    );
  }
  //========================NOT IN USE=======================

  const getPrescence = useCallback(
    (jid) => {
      var check = $pres({
        type: 'probe',
        to: jid,
      });
      connection.current.send(check);
    },
    [connection],
  );

  /* Fetch chat history based on user id
   * @param {*} jid - your id
   * @param {*} withJid - to whom you want chat history user id
   * @param {*} messageCount - no. of messages
   * @param {*} callback
   */
  const getHistory = (filters, list, callback) => {
    //cleanup current messages
    setMessages({});
    let localMsg = {};
    let localChatFirstMessage = null;
    let isPagination = filters?.after || filters?.before;
    setFetchingHistory(true);
    connection.current.mam.query(Strophe.getBareJidFromJid(getFullJid(userId)), {
      max: 50,
      ...filters,
      onMessage: function (message) {
        const messageObj = convertMessageXMLtoJson(message);
        localChatFirstMessage = localChatFirstMessage
          ? localChatFirstMessage
          : messageObj?.id
          ? messageObj
          : null;
        if (!list) {
          localMsg = messageGroupByDay(localMsg, messageObj);
        } else {
          callback(messageObj.text);
          return messageObj.text;
        }
        return true;
      },
      onComplete: function (response) {
        let isPaginationComplete = response.getElementsByTagName('fin')[0].getAttribute('complete');
        setMessages(localMsg);
        if (isPaginationComplete === 'false' && localChatFirstMessage) {
          setChatFirstMessage(localChatFirstMessage);
        }
        setFetchingHistory(false);
      },
    });
  };
  return {
    status,
    chatFirstMessage,
    typing,
    recStatus,
    currentAppointmentUser,
    setUnreadMsgCount,
    fetchingHistory,
    fetchAllChatHistory,
    messages,
    rawMessages,
    getHistoryFromTk,
    getPrescence,
    sendMessage,
    scrollWayUp,
  };
};

export const ChatContext = React.createContext();

export const ChatProvider = (props) => {
  const { userId, password } = props;
  const value = useAcStrophe(userId, password);
  return <ChatContext.Provider value={value} {...props} />;
};
