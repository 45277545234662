import normal from 'assets/images/vectors/normal.png';
import abnormal from 'assets/images/vectors/abnornal.png';
import critical from 'assets/images/vectors/critical.png';
import noAnalysis from 'assets/images/vectors/noAnalysis.png';
import satisified from 'assets/images/vectors/satisified.png';
import heart from 'assets/images/vectors/heart.png';
import forwardarrow from 'assets/images/vectors/forward.png';
import abnormal_img from 'assets/images/vectors/crisis.png';
import back from 'assets/images/vectors/backward_new.png';
import noresult from 'assets/images/vectors/newNo_result.png'
import updatedabnormal from 'assets/images/vectors/NewAbnormal.png'
import updatedneedsattentation from 'assets/images/vectors/Newneeds_Attentation.png'
import updatedcrisic from 'assets/images/vectors/Newcrisic.png'
import  updatedcritical from 'assets/images/vectors/Newsevere.png'

export const EKG_EMOJI = {
  NORMAL: normal,
  NORMAL_SINUS_RHYTHM: normal,
  OTHER: abnormal,
  TACHYCARDIA: abnormal,
  BRADYCARDIA: abnormal,
  HEART_RATE_OVER_120: abnormal,
  HEART_RATE_UNDER_50: abnormal,
  INCONCLUSIVE: abnormal,
  AFIB: critical,
  SINUS_RHYTHM: critical,
  WIDE_QRS: critical,
  SINUSRHYTHM: critical,
  'SINUS_RHYTHM,WIDE_QRS': critical,
  NO_ANALYSIS: noAnalysis,
  TOO_SHORT: noAnalysis,
  TOO_LONG: noAnalysis,
  UNREADABLE: noAnalysis,
  INCONCLUSIVE_POOR_READING: noAnalysis,
  SATISIFIED: satisified,
  HEART: heart,
  BACKWARD_ARROW: back,
  FORWARD_ARROW: forwardarrow,
};

export const EMOJI_ECG_REVIEW = {
  NO_RESULT: noresult,
  SATISIFIED: satisified,
  ABNORMAL: updatedabnormal,
  NEED_ATTENTION: updatedneedsattentation,
  SEVERE: updatedcrisic,
  CRITICAL:updatedcritical
};
