import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { EKG_EMOJI } from 'assets/assetPath/constant';
import EkgRecording from 'components/chart/EkgRecording';
import { useSelector } from 'react-redux';
import Ecgstatus from './Ecgstatus';
import Ecgdevicedata from './Ecgdevicedata';
import EcgHeartratedata from './EcgHeartratedata';
import { useParams } from 'react-router';
import { completed, pending } from 'constants/app';
import CountdownTimer from 'react-component-countdown-timer';
import moment from 'moment';



const EcgHeader = ({
  aiDeterminationDetails,
  devicedetails,
  heartrate,
  navigateBack,
}) => {
  const { reviewStatus } = useParams();
  let key = 1;
  // Data is coming from redux Store
  const patientData = useSelector((state) => state.ekgReview.patientInfo);
  const recordingSample = useSelector((state) => state.ekgReview.recordingSample);
  const { timeSubmit } = useSelector((state) => state.ekgReview?.reviewDetail);

  return (
    <Grid item xs={12}>
      <Box style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', marginBottom: '16px' }}>
          <Typography style={{ marginRight: '24px' , fontSize:"24px" }}>ECG</Typography>
          <Ecgstatus userStatus={aiDeterminationDetails} />
          <Ecgdevicedata devicedetails={devicedetails} />
          <EcgHeartratedata heartrate={heartrate} />
        </Box>      
            {reviewStatus === pending && <Box>
                <Typography
                  sx={{ display: 'flex', flexDirection: 'row', columnGap: 1 }}
                  style={{ marginLeft: 'auto' }}
                >
                  {'Time Remaining: '}
                  <CountdownTimer
                    responsive
                    count={300 - moment().diff(moment(timeSubmit), 'seconds', true)}
                    size={15}
                    hideDay
                    hideHours
                    hideTitle
                    border
                    noPoints={false}
                    onEnd={navigateBack}
                  />
                </Typography>
              </Box> }
      </Box>
      {recordingSample && (
        <EkgRecording
          key={key++}
          height={700}
          width={1500}
          recording={recordingSample}
          recordingSample={recordingSample}
          rowSeconds={15}
          calpulse={true}
          inverted={recordingSample.inverted} // using inverted flag from api response.
          is6l={Object.keys(recordingSample.samples).length === 6}
          duration={patientData?.duration}
        />
      )}
    </Grid>
  );
};

export default EcgHeader;
